<template>
  <div class="error-404">
    <h1>404</h1>
    <p class="body-2">
      Parece que la página que buscas no existe. Intenta navegar a otra página.
    </p>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Error404",
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.error-404 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 150px;
    font-weight: bold;
  }
}
</style>
