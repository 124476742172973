<template>
  <v-row>
    <v-col cols="12">
      <v-card class="elevation-0">
        <v-card-title>
          <h2>Gastos</h2>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="gastos"
          :loading="loading"
          loading-text="Cargando gastos..."
          class="elevation-0"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:item.tecnico="{ item }">
            {{ item.usuario.nombre }}
          </template>

          <template v-slot:item.acciones="{ item }">
            <v-btn
              color="primary"
              class="mr-2"
              dark
              small
              depressed
              v-if="_auth.id == item.usuario_id"
              :to="`/editar/gasto/${proyecto.id}/${item.id}`"
            >
              <v-icon small>
                mdi-pencil
              </v-icon>
            </v-btn>

            <v-btn
              color="error"
              depressed
              class="mr-2"
              small
              outlined
              v-if="_auth.id == item.usuario_id"
              @click="_delete(proyecto.id, item.id)"
            >
              <v-icon small>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>

      <div class="mt-3 text-right">
        <v-pagination
          :length="pagination"
          v-model="page"
          class="elevation-0"
        ></v-pagination>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  name: "Gastos",
  props: ["proyecto"],
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_SERVER,
      loading: true,
      dialog: false,
      page: 1,
      pagination: null,
      gastos: [],
      headers: [
        { text: "Técnico", value: "tecnico" },
        { text: "Fecha", value: "fecha" },
        { text: "Km", value: "km" },
        { text: "Gasto", value: "gastos" },
        { text: "Acciones", value: "acciones" },
      ],
    };
  },
  mounted() {
    this._loadGastos();
  },
  watch: {
    page() {
      this._loadGastos(null);
    },
  },
  computed: {
    ...mapState({
      _auth: "auth",
    }),
  },
  methods: {
    _loadGastos(del) {
      this.loading = true;
      if (!del) this.gastos = [];
      let params = {
        params: {
          page: this.page,
          proyecto_id: this.proyecto.id,
        },
      };
      axios.get(`/gastos`, params).then((res) => {
        if (res.data.status_code == 200) {
          this.gastos = res.data.data.data;
          this.pagination = res.data.data.last_page;
          this.loading = false;
        }
      });
    },
    _delete(proyecto_id, id) {
      this.loading = true;
      axios.delete(`/gasto/${id}`).then((res) => {
        if (res.data.status_code == 200) {
          let del = res.data.data;

          if (del) {
            this._loadGastos(del);
            this.$emit("delete", proyecto_id);
            let snackbar = {
              status: true,
              message: "El gasto se ha borrado correctamente.",
              type: "success",
            };

            this.$store.commit("_SNACKBAR", snackbar);
          }
        }
        this.loading = false;
      });
    },
  },
};
</script>
