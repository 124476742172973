<template>
  <div>
    <div class="text-center loader" v-if="loading">
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </div>

    <v-card v-else class="elevation-0">
      <template v-if="usuario_id == null || usuario_id == _auth.id">
        <v-card-title>
          <h2 v-if="id">Editar Actividad</h2>
          <h2 v-else>Nueva Actividad</h2>
          <v-chip v-if="id" class="ma-2" label>
            Proyecto: {{ proyecto.codigo }}
          </v-chip>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-select
                v-if="_tipoActividades"
                v-model="tipo_actividad_id"
                :items="_tipoActividades"
                label="Tipos de actividades"
                outlined
                dense
                item-text="nombre"
                item-value="id"
              ></v-select>

              <v-select
                v-model="tarea_id"
                :items="proyecto.tareas"
                label="Tarea"
                outlined
                dense
                item-text="tipo_tarea.nombre"
                item-value="id"
              ></v-select>

              <v-menu
                ref="fecha"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fecha"
                    label="Fecha"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fecha"
                  color="primary"
                  no-title
                  scrollable
                  locale="es"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="hora_inicio"
                label="Hora inicio"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="hora_final"
                label="Hora final"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-card tile outlined class="pa-5 mb-3 grey lighten-4">
                <v-file-input
                  v-model="archivos"
                  multiple
                  label="Archivos | Documentos"
                  @change="_archivos"
                  outlined
                  dense
                ></v-file-input>
              </v-card>

              <v-card class="mx-auto" tile outlined v-if="archivosCargados">
                <v-list dense>
                  <v-subheader>Archivos de esta actividad</v-subheader>
                  <v-list-item-group color="primary">
                    <v-list-item
                      v-for="(archivo, i) in archivosCargados"
                      :key="i"
                    >
                      <v-list-item-icon>
                        <v-icon
                          v-if="archivo.size / 1000 > 2000"
                          class="archivo-error"
                          >mdi-file-alert-outline</v-icon
                        >
                        <v-icon v-else>mdi-file-document-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          <template v-if="archivo.nombre_original">
                            {{ archivo.nombre_original }}
                          </template>

                          <template v-else>
                            {{ archivo.name }}
                          </template>
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-icon>
                        <v-btn
                          color="white"
                          small
                          depressed
                          dark
                          @click="_removeArchivo(i)"
                        >
                          <v-icon small color="black">
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-4 pb-6">
          <v-btn
            color="primary"
            class="px-4"
            large
            depressed
            @click="_saveActividad()"
            :loading="loadingButton"
            :disabled="loadingButton"
          >
            <v-icon class="mr-2">
              mdi-cloud-upload
            </v-icon>
            Guardar actividad
          </v-btn>
        </v-card-actions>
      </template>

      <v-alert v-else text type="error">
        No tienes permisos para editar esta actividad.
      </v-alert>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  props: ["proyectoProp", "actividad"],
  data() {
    return {
      loading: true,
      loadingButton: false,
      uploadLoader: false,
      archivos: null,
      archivosCargados: [],
      currentActividad: null,
      tipoActividades: false,
      proyecto: false,
      id: null,
      tipo_actividad_id: null,
      tarea_id: null,
      usuario_id: null,
      fecha: null,
      hora_inicio: null,
      hora_final: null,
      km: null,
      peajes: 0,
      gastos: null,
    };
  },
  computed: {
    ...mapState({
      _auth: "auth",
      _tipoTareas: "tipoTareas",
      _tipoActividades: "tipoActividades",
    }),
  },
  watch: {
    proyectoProp(val) {
      this._loadActividad(val);
    },
  },
  mounted() {
    this.tipoActividades = this.$store.state.tipoActividades;
  },
  methods: {
    _loadActividad(val) {
      if (val) {
        val.tareas.forEach((tarea) => {
          tarea.actividades.filter((actividad) => {
            if (actividad.id == this.actividad) {
              this.currentActividad = actividad;
            }
          });
        });

        this.proyecto = val;
        if (this.currentActividad) {
          this.id = this.currentActividad.id;
          this.tipo_actividad_id = this.currentActividad.tipo_actividad_id;
          this.tarea_id = this.currentActividad.tarea_id;
          this.usuario_id = this.currentActividad.usuario_id;
          this.fecha = this.currentActividad.fecha;
          this.hora_inicio = this.currentActividad.hora_inicio;
          this.hora_final = this.currentActividad.hora_final;
          this.archivosCargados = this.currentActividad.archivos;
        } else {
          this.usuario_id = this._auth.id;
        }
      } else {
        this.id = null;
        this.tipo_actividad_id = null;
        this.tarea_id = null;
        this.usuario_id = this._auth.id;
        this.fecha = null;
        this.hora_inicio = null;
        this.hora_final = null;
        this.archivosCargados = [];
      }
      this.loading = false;
    },
    _archivos() {
      this.uploadLoader = true;

      this.archivos.forEach((item) => {
        this.archivosCargados.push(item);
      });
      setTimeout(() => {
        this.uploadLoader = false;
      }, 500);
    },
    async _saveActividad() {
      this.loadingButton = true;
      let params = {
        id: this.id,
        proyecto_id: this.proyecto.id,
        tipo_actividad_id: this.tipo_actividad_id,
        tarea_id: this.tarea_id,
        usuario_id: this.usuario_id,
        fecha: this.fecha,
        hora_inicio: this.hora_inicio,
        hora_final: this.hora_final,
      };

      const actividad = await axios.post("actividad", params);
      let snackbar = null;
      if (actividad.data.status_code != 200) {
        snackbar = {
          status: true,
          message: actividad.data.data,
          type: "error",
        };
      } else {
        snackbar = {
          status: true,
          message: "La actividad se ha guardado correctamente.",
          type: "success",
        };

        // Si hay archivos nuevos que subir
        if (this.archivos) {
          let cleanArray = [];
          this.archivosCargados.forEach((item) => {
            if (!item.id) cleanArray.push(item);
          });

          try {
            const filesForUpload = cleanArray;
            const formData = new FormData();
            if (filesForUpload.length) {
              filesForUpload.map((file) => {
                formData.append("archivos[]", file);
                formData.append("actividad_id", actividad.data.data.id);
              });
              let upload = await axios.post("archivos", formData, {
                headers: {
                  "content-type": "multipart/form-data",
                },
              });
              if (upload.data.status_code != 200) {
                let errors = upload.data.data;
                snackbar = {
                  status: true,
                  message: errors,
                  type: "error",
                };
              }
            }
          } catch (e) {
            console.log(e);
          }
        }
      }

      this.$store.commit("_SNACKBAR", snackbar);
      this.loadingButton = false;
    },
    async _removeArchivo(el) {
      this.archivosCargados.map(async (item, i) => {
        if (i == el) {
          if (item.id) {
            await axios.delete(`archivos/${item.id}`);
          }

          this.archivosCargados.splice(el, 1);

          let snackbar = {
            status: true,
            message: "El archivo ha sido eliminado.",
            type: "success",
          };
          this.$store.commit("_SNACKBAR", snackbar);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.archivo-error {
  color: red;
}
</style>
