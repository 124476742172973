<template>
  <div class="controles">
    <!-- Repeater técnicos -->
    <v-card class="elevation-0">
      <v-card-title>
        <h2>Comparativa entre técnicos</h2>
        <v-chip class="ml-3 text-uppercase" label>Cargas horarias </v-chip>
      </v-card-title>

      <v-card-text class="pb-0 mt-3">
        <v-row class="mb-0">
          <v-col cols="6">
            <v-menu
              ref="desde"
              v-model="menu"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="desde"
                  label="Desde"
                  prepend-icon="mdi-calendar"
                  readonly
                  hide-details="true"
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="desde"
                color="primary"
                no-title
                scrollable
                locale="es"
                hide-details="true"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-menu
              ref="hasta"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="hasta"
                  label="Hasta"
                  prepend-icon="mdi-calendar"
                  hide-details="true"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="hasta"
                color="primary"
                no-title
                scrollable
                locale="es"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="pb-0">
        <v-alert v-if="!tecnicos.length" dense text type="info">
          No has seleccionado ningún técnico a comparar
        </v-alert>

        <div
          v-else
          v-for="(item, index) in tecnicos"
          :key="index"
          :id="`tecnico_${index}`"
        >
          <v-row>
            <v-col cols="11" class="pb-0 mb-4">
              <v-select
                v-if="_tecnicos"
                v-model="tecnicos[index]"
                :items="_tecnicosComputed"
                label="Tecnico"
                outlined
                hide-details="true"
                dense
                item-text="nombre"
                item-value="id"
                prepend-icon="mdi-account"
              ></v-select>
            </v-col>
            <v-col cols="1" class="pb-0">
              <v-btn
                color="white"
                depressed
                class="mr-2 delete"
                dark
                @click="_removeTecnico(index)"
              >
                <v-icon small color="black">
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>

        <v-col cols="12" class="pb-0">
          <v-btn
            color="black"
            class="ml-6 mb-6"
            small
            dark
            depressed
            @click="_addTecnico()"
          >
            <v-icon small class="mr-1">
              mdi-plus
            </v-icon>
            Añadir técnico
          </v-btn>
        </v-col>
      </v-card-text>

      <v-card-actions class="px-0 pb-6">
        <v-btn
          color="primary"
          class="px-8 ml-12"
          large
          depressed
          @click="_comparar()"
          :loading="loadingButton"
          :disabled="loadingButton"
        >
          <v-icon class="mr-2">
            mdi-eye
          </v-icon>
          Comparar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card
      v-if="comprobacion"
      class="mt-5 pa-4 elevation-0"
      style="display:flex"
    >
      <v-card class="pa-0" outlined tile style="max-width: 200px;">
        <div class="col py-3 text-left" style="height: 43px;"></div>

        <div class="control-container">
          <div class="control-container-div">
            <v-chip label class="text-uppercase">
              Técnico
            </v-chip>

            <v-chip label color="white" class="text-uppercase">
              Horas reales
            </v-chip>

            <v-chip label color="white" class="text-uppercase">
              Horas asignadas
            </v-chip>

            <v-chip label color="white" class="text-uppercase">
              Rentabilidad Técnica
            </v-chip>

            <v-chip label color="white" class="text-uppercase">
              Rentabilidad Real
            </v-chip>
          </div>
        </div>
      </v-card>

      <div class="crono-col-container">
        <div
          class="crono-col"
          v-for="(month, i) in monthsDifferenceLoop"
          :key="i"
          :style="
            monthsDifference < 24
              ? `flex: 0 0 ${100 / monthsDifference}%;`
              : `flex: 0 0 110px;`
          "
        >
          <v-card class="pa-0" outlined tile>
            <div class="col py-3 text-left">
              <!-- grey darken-4 white--text text--darken-2 -->
              <h5 class="text-truncate text-uppercase mb-0">
                {{ monthNames[month.getMonth()] }}
                <v-chip class="ml-3 text-uppercase" x-small>
                  {{ moment(month).year() }}
                </v-chip>
              </h5>
            </div>

            <div class="control-container">
              <div
                v-for="(id, i) in tecnicosLoop"
                :key="i"
                class="control-container-div"
              >
                <v-chip
                  label
                  :color="colors[i]"
                  class="text-uppercase white--text"
                >
                  <strong>{{ _getName(id) }}</strong>
                </v-chip>

                <v-chip label color="white">
                  <strong> {{ _formatFloat(_getReal(month, id)) }}</strong>
                </v-chip>

                <v-chip label color="white" class="text-center">
                  <strong> {{ _formatFloat(_getPlan(month, id)) }}</strong>
                </v-chip>

                <v-chip label color="white" class="text-center">
                  <strong>
                    {{
                      _formatFloat(
                        _getRentabilidadTecnica(
                          _getReal(month, id),
                          _getPlan(month, id)
                        )
                      )
                    }}
                    %</strong
                  >
                </v-chip>

                <v-chip label color="white" class="text-center">
                  <strong
                    >{{
                      _formatFloat(
                        _getRentabilidadReal(_getReal(month, id), month)
                      )
                    }}
                    %</strong
                  >
                </v-chip>
              </div>
            </div>
          </v-card>
        </div>
      </div>
    </v-card>
    <!-- Repeater técnicos -->
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import "moment/locale/es";
import { mapState } from "vuex";
export default {
  name: "Comprara Técnicos",
  data() {
    return {
      loading: true,
      loadingButton: false,
      menu: false,
      comprobacion: false,
      tecnicosLoop: null,
      monthsDifferenceLoop: null,
      tecnicos: [],
      tecnico: null,
      compararProyectos: [],
      compararActividades: [],
      horasProductivasMes: [],
      desde: null,
      hasta: null,
      monthsDifference: 0,
      months: [],
      monthNames: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      colors: ["primary", "cyan", "teal", "indigo"],
    };
  },
  computed: {
    ...mapState({
      _tecnicos: "tecnicos",
      _datosAdministrativos: "datosAdministrativos",
    }),
    _tecnicosComputed() {
      return this._tecnicos.filter((res, index) => {
        if (index > 0) return res;
      });
    },
  },
  // mounted() {
  //   this._addTecnico();
  // },
  methods: {
    _comparar() {
      if (!this.tecnicos.length || !this.desde || !this.hasta) {
        let snackbar = {
          status: true,
          message: "Tienes que llenar todos los campos.",
          type: "error",
        };
        this.$store.commit("_SNACKBAR", snackbar);
        return true;
      }
      this.comprobacion = false;
      this.loadingButton = true;
      this.tecnicosLoop = [];
      let params = {
        params: {
          tecnicos: this.tecnicos,
          desde: this.desde,
          hasta: this.hasta,
        },
      };
      axios.get("comparar-tecnicos", params).then((res) => {
        if (res.data.status_code == 200) {
          this.tecnicosLoop = this._getTecnicos(this.tecnicos);
          this.monthsDifferenceLoop = this._monthsDifference();
          this.compararActividades = res.data.data[0];
          this.compararProyectos = res.data.data[1];
        }
        this.loadingButton = false;
        this.comprobacion = true;
      });
    },
    _addTecnico() {
      this.tecnico = null;
      this.tecnicos.push(this.tecnico);
    },
    _removeTecnico(el) {
      this.tecnicos.map((item, i) => {
        if (i == el) {
          this.tecnicos.splice(i, 1);
        }
      });
    },
    _monthsDifference() {
      let months;
      let diff;
      let monthsArray = [];

      // asignamos valores de fechas.
      let fecha_inicio = new Date(this.desde);
      let fecha_final = new Date(this.hasta);

      months = (fecha_final.getFullYear() - fecha_inicio.getFullYear()) * 12;
      months -= fecha_inicio.getMonth();
      months += fecha_final.getMonth();
      diff = months <= 0 ? 0 : months;

      for (let i = 0; i <= diff; i++) {
        // mostramos al menos un mes.
        if (i == 0) {
          monthsArray.push(
            new Date(fecha_inicio.setMonth(fecha_inicio.getMonth()))
          );
        } else {
          monthsArray.push(
            new Date(fecha_inicio.setMonth(fecha_inicio.getMonth() + 1))
          );
        }
      }
      this.monthsDifference = monthsArray.length;
      return monthsArray;
    },
    _getReal(month, usuario_id) {
      let sum = moment.duration("00:00");
      this.compararActividades.filter((usuario) => {
        if (usuario_id == usuario.id) {
          usuario.actividades.forEach((act) => {
            if (moment(act.fecha).isSame(month, "month")) {
              var startTime = moment(act.hora_inicio, "HH:mm");
              var endTime = moment(act.hora_final, "HH:mm");
              // calculate total duration
              var duration = moment.duration(endTime.diff(startTime));
              // duration in hours
              var hours = parseInt(duration.asHours());
              // duration in minutes
              var minutes = parseInt(duration.asMinutes()) % 60;
              sum.add(moment.duration(`${hours}:${minutes}`));
            }
          });
        }
      });
      return moment.duration(sum).asHours();
      // return moment.utc(sum.as("milliseconds")).format("HH:mm"); //h [hrs], m [min]
    },
    _getPlan(month, usuario_id) {
      //let sum = moment.duration("00:00");
      let sum = 0;
      this.compararProyectos.filter((proyecto) => {
        if (usuario_id == proyecto.usuario_id) {
          // loque nos hace falta para calcular todo
          let inicio = proyecto.proyecto.fecha_inicio;
          let final = proyecto.proyecto.fecha_final;
          let horas_asignadas = proyecto.proyecto.horas_asignadas;
          let participacion = proyecto.participacion;

          // comprobar si este proyecto se desarrolla este mes sino salir o mostrar 0
          let from = moment(inicio, "YYYY-MM");
          let to = moment(final, "YYYY-MM").add(1, "M");
          let check = moment(month, "YYYY-MM");
          if (check.isBetween(from, to)) {
            // hacer la formula
            let diff = moment(final, "YYYY-MM")
              .add(1, "M")
              .diff(moment(inicio, "YYYY-MM"), "months", true);

            sum = sum + (participacion * (horas_asignadas / diff)) / 100;
          }
        }
      });
      return sum;
    },
    _getRentabilidadReal(real, month) {
      let currentAño = moment(month).year();
      let datos = this._datosAdministrativos;
      let horasMes = 0;
      let response = 0;

      datos.forEach((res, index) => {
        if (index == 1) {
          res.años.forEach((año) => {
            let key = Object.keys(año).filter((k) => {
              if (
                currentAño == año.año &&
                k ==
                  moment(month)
                    .locale("es")
                    .format("MMMM")
              ) {
                return k;
              }
            });

            horasMes = año[key[0]];
            response = ((real / horasMes) * 100).toFixed(2);
          });
        }
      });
      return response;
    },
    _getRentabilidadTecnica(real, asignada) {
      if (real == 0 || asignada == 0) return 0;
      return ((real / asignada) * 100).toFixed(2);
    },
    _getName(id) {
      let nombre = null;
      this._tecnicos.forEach((usuario) => {
        if (usuario.id == id) {
          nombre = usuario.nombre;
        }
      });
      return nombre;
    },
    _getTecnicos(val) {
      let array = [];
      val.forEach((res) => {
        array.push(res);
      });
      return array;
    },
    _formatFloat(val) {
      if (!val) return "0,00";

      if (isNaN(val)) return "-";
      return (val * 1)
        .toFixed(2) // always two decimal digits
        .replace(".", ",") // replace decimal point character with ,
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    },
  },
};
</script>

<style lang="scss" scoped>
.control-container {
  .v-chip--label {
    width: 100% !important;
    border-radius: 0 !important;
  }
}

.crono-col-container {
  display: flex;
  align-items: stretch;
  justify-items: flex-start;
  flex-wrap: nowrap; //wrap
  justify-content: flex-start;
  overflow-x: auto;
  width: 100%;
}

.control-container {
  display: flex;
  &-div {
    width: 100%;
  }
}

.v-card {
  height: 100%;
}
</style>
