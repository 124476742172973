<template>
  <div class="controles">
    <!-- Repeater técnicos -->
    <v-card class="elevation-0">
      <v-card-title>
        <h2>Proyectos fuera de plazo</h2>
        <v-chip class="ml-3 text-uppercase" label
          >Sobre el Conjunto de proyectos totales</v-chip
        >
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="proyectos"
        class="elevation-0"
        :loading="loading"
        loading-text="Cargando proyectos..."
        hide-default-footer
      >
        <template v-slot:item.estado="{ item }">
          <v-chip
            small
            label
            class="my-2"
            :color="_getChipEstado(item.estado.nombre)"
            outlined
            pill
          >
            {{ item.estado.nombre }}
          </v-chip>
        </template>

        <template v-slot:item.tecnicos="{ item }">
          <v-chip
            v-for="tecnico in item.tecnicos"
            :key="tecnico.id"
            class="mr-1"
            small
            label
            >{{ tecnico.usuario.nombre }}</v-chip
          >
        </template>

        <template v-slot:item.fuera_plazo="{ item }">
          <v-chip label color="error" v-if="item.fuera_plazo">{{
            item.fuera_plazo.fecha_final_prevista
          }}</v-chip>
        </template>

        <template v-slot:item.acciones="{ item }">
          <v-btn
            color="primary"
            depressed
            class="mr-2"
            small
            dark
            :to="`/editar/proyecto/${item.id}`"
          >
            <v-icon small>
              mdi-pencil
            </v-icon>
          </v-btn>

          <v-btn
            color="dark"
            small
            depressed
            dark
            :to="`/proyectos/${item.id}`"
          >
            <v-icon small>
              mdi-eye
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:no-data>
          <v-alert text type="info" class="text-left my-2" tile>
            No hay proyectos <strong>Fuera de plazo</strong>.
          </v-alert>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  name: "Proyectos",
  data() {
    return {
      loading: true,
      page: 1,
      pagination: null,
      headers: [
        { text: "Cliente", value: "cliente.nombre_comercial" },
        { text: "Técnico(s)", value: "tecnicos" },
        { text: "Fecha fin planif.", value: "fecha_final" },
        { text: "Fecha fin prev.", value: "fuera_plazo" },
        { text: "Causa", value: "fuera_plazo.causa" },
        { text: "Acciones", value: "acciones" },
      ],
      proyectos: [],
      loadProyectos: false,
      estado_id: 2,
    };
  },
  mounted() {
    this._loadProyectos();
  },
  watch: {
    page() {
      this._loadProyectos();
    },
  },
  computed: {
    ...mapState({
      _estados: "tipoEstados",
      _tipoProyectos: "tipoProyectos",
      _tipoEsquemas: "tipoEsquemas",
      _datosAdministrativos: "datosAdministrativos",
    }),
    _estadosComputed() {
      return this._estados.filter((res, index) => {
        if (index > 0) return res;
      });
    },
  },
  methods: {
    _loadProyectos() {
      this.loading = true;
      this.proyectos = [];
      let params = {
        params: {
          page: this.page,
          estado_id: this.estado_id,
        },
      };
      axios.get(`/proyectos`, params).then((res) => {
        if (res.data.status_code == 200) {
          this.proyectos = res.data.data.data;
          this.pagination = res.data.data.last_page;
        }
        this.loading = false;
      });
    },
    _getChipEstado(estado) {
      let color = "success";
      switch (estado) {
        case "Abierto":
          color = "success";
          break;
        case "Cerrado":
          color = "error";
          break;
        case "Abierto Fuera de Plazo":
          color = "error";
          break;
        case "Inactivo":
          color = "grey";
          break;
      }
      return color;
    },
    _limitWords(textToLimit, wordLimit = 2, suffix = "...") {
      var finalText = "";
      var text2 = textToLimit.replace(/\s+/g, " ");
      var text3 = text2.split(" ");
      var numberOfWords = text3.length;
      var i = 0;
      if (numberOfWords > wordLimit) {
        for (i = 0; i < wordLimit; i++) {
          finalText = finalText + " " + text3[i] + " ";
        }
        return finalText + suffix;
      } else {
        return textToLimit;
      }
    },
  },
};
</script>
