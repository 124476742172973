<template>
  <div class="clientes">
    <h1>Clientes</h1>

    <!-- accion para borrar -->
    <v-dialog v-model="borrarDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="text-h5">
          Borrar cliente
        </v-card-title>
        <v-card-text
          >¿Estás seguro de eliminar el cliente? No se podrán recuperar los
          datos.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="borrarDialog = false">
            No
          </v-btn>
          <v-btn color="primary" text @click="_deleteComplete()">
            Sí
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-btn
      color="primary"
      class="my-3 mb-6"
      dark
      depressed
      v-if="_auth.a"
      :to="`/editar/cliente`"
    >
      <v-icon class="mr-2">
        mdi-plus
      </v-icon>
      Añadir Cliente
    </v-btn>

    <v-toolbar class="mb-2" color="white" flat>
      <v-text-field
        v-model="busqueda"
        label="Buscar por cliente"
        dense
        hide-details="true"
        outlined
        required
      ></v-text-field>

      <v-spacer></v-spacer>
    </v-toolbar>

    <v-chip
    v-for="item in clientesChip" :key="item.id"
      label
      class="ma-1"
      outlined
      pill
      @click="_setCliente(item)"
    >
      {{ item.nombre_comercial}}
    </v-chip>


    <v-card class="elevation-0">
      <v-data-table
        :headers="headers"
        :items="clientes"
        class="elevation-0"
        :loading="loading"
        loading-text="Cargando clientes..."
        hide-default-footer
        disable-pagination
      >
        <template v-slot:item.razon_social="{ item }">
          <v-chip class="text-uppercase" label>{{ item.razon_social }} </v-chip>
        </template>

        <template v-slot:item.acciones="{ item }">
          <v-btn
            color="primary"
            depressed
            class="mr-2"
            small
            dark
            :to="`/editar/cliente/${item.id}`"
          >
            <v-icon small> mdi-pencil </v-icon>
          </v-btn>

          <v-btn
            color="error"
            depressed
            class="mr-2"
            small
            outlined
            v-if="item.id && _auth.a"
            @click="_delete(item.id)"
          >
            <v-icon small>
              mdi-delete
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:no-data>
          <v-alert text type="info" class="text-left my-2" tile>
            No hay clientes que mostrar.
          </v-alert>
        </template>
      </v-data-table>
    </v-card>

    <div class="mt-3 text-right">
      <v-pagination
        :length="pagination"
        v-model="page"
        class="elevation-0"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "Datos",
  data() {
    return {
      loading: true,
      page: 1,
      pagination: null,
      clientes: [],
      clientesChip: [],
      borrarDialog: false,
      deleteItem: false,
      busqueda: null,
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "nombre_comercial",
        },
        { text: "Razón social", value: "razon_social" },
        { text: "Acciones", aligns: "end", value: "acciones" },
      ],
    };
  },
  mounted() {
    this._loadClientes(null);
  },
  watch: {
    page() {
      this._loadClientes(null);
    },
    busqueda(val) {
      if(val.length == 0 ){
        this.cliente_id = null;
        this.clientes = []
        this._loadClientes()
      }

      if (val.length > 0 && val.length < 3) {
        this.clientesChip = []
        return;
      }
      this._searchCliente()
    },
  },
  computed: {
    ...mapState({
      _auth: "auth",
    }),
  },
  methods: {
    _setCliente(val) {
      this.clientes = [];
      this.busqueda = val.nombre_comercial
      this.pagination= null;
      this.clientes.push(val)
     
    },  
    _loadClientes() {
      this.loading = true;
      
      let params = {
        params: {
          page: this.page,
          pagination: 30,
        },
      };
      axios.get(`/clientes`, params).then((res) => {
        if (res.data.status_code == 200) {
          this.clientes = res.data.data.data;
          this.pagination = res.data.data.last_page;
        }
        this.loading = false;
      });
    },
    _searchCliente() {
      this.loading = true;
      axios.get(`/clientes/buscar/${this.busqueda}`).then((res) => {
        if (res.data.status_code == 200) {
          this.clientesChip = res.data.data;
        }
        this.loading = false;
      });
    },
    _delete(id) {
      this.borrarDialog = true;
      this.deleteItem = id;
    },
    _deleteComplete() {
      this.loading = true;
      if (!this.deleteItem) {
        this.borrarDialog = false;
        return true;
      }
      axios.delete(`/clientes/${this.deleteItem}`).then((res) => {
        if (res.data.status_code == 200) {
          let del = res.data.data;

          if (del) {
            this._loadClientes(del);
            this.$store.dispatch("_clientes");
            let snackbar = {
              status: true,
              message: "El cliente se ha borrado correctamente.",
              type: "success",
            };

            this.$store.commit("_SNACKBAR", snackbar);
          }
        }
        this.loading = false;
        this.borrarDialog = false;
      });
    },
  },
};
</script>
