<template>
  <div>
    <div class="text-center loader" v-if="loading">
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </div>

    <v-card v-else class="elevation-0">
      <v-card-title>
        <h2 v-if="id">Editar Proyecto</h2>
        <h2 v-else>Nuevo Proyecto</h2>
      </v-card-title>
      <v-card-text>
        <v-dialog v-model="sendMailDialog" max-width="290">
          <v-card class="pa-4">
            <v-card-text class="px-0">
              <h3 class="mb-3">¿Enviar email a los técnicos?</h3>
              <v-radio-group v-model="send_mail" column>
                <v-radio label="Sí" :value="1"></v-radio>
                <v-radio label="No" :value="0"></v-radio>
              </v-radio-group>
            </v-card-text>
            <v-btn
              color="primary"
              class="px-4"
              large
              depressed
              @click="_saveProyecto()"
              :loading="loadingButton"
              :disabled="loadingButton"
            >
              <v-icon class="mr-2">
                mdi-email-send
              </v-icon>
              Continuar
            </v-btn>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog" persistent max-width="290">
          <v-card class="pa-4">
            <template v-if="loadingTareas">
              <v-card-text class="text-center">
                <h3 class="mb-3">Cargando tareas...</h3>
                <v-progress-circular
                  :size="40"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-card-text>
            </template>
            <template v-else>
              <v-card-title class="px-0">¡Atención!</v-card-title>
              <v-card-text class="px-0">
                ¿Quieres cargar las tareas asociadas a este tipo de proyecto?
                Recuerda que estos cambios pueden afectar a proyectos que estan
                activos.
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" text @click="dialog = false">
                  No
                </v-btn>
                <v-btn color="primary darken-1" text @click="_getTipoTareas()">
                  Sí
                </v-btn>
              </v-card-actions>
            </template>
          </v-card>
        </v-dialog>

        <v-row>
          <v-col cols="12">
            Código único para este proyecto

            <v-text-field
              outlined
              v-model="codigo"
              label="Código"
              hide-details="true"
              class="mt-2"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-if="_tipoProyectos"
              v-model="tipo_proyecto_id"
              :items="_tipoProyectos"
              label="Tipos de proyectos"
              @change="dialog = true"
              hide-details="true"
              dense
              outlined
              item-text="nombre"
              item-value="id"
            ></v-select>
          </v-col>

          <v-col cols="12" md="6">
            <v-select
              v-if="_tipoEsquemas"
              v-model="tipo_esquema_id"
              :items="_tipoEsquemas"
              label="Tipos de esquemas"
              hide-details="true"
              dense
              outlined
              item-text="nombre"
              item-value="id"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-select
              v-if="_clientes"
              v-model="cliente_id"
              :items="_clientesComputed"
              label="Clientes"
              hide-details="true"
              dense
              outlined
              item-text="nombre_comercial"
              item-value="id"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-if="_estados"
              v-model="estado_id"
              :items="_estadosComputed"
              label="Estado"
              hide-details="true"
              dense
              outlined
              item-text="nombre"
              item-value="id"
            ></v-select>
          </v-col>
        </v-row>

        <v-dialog v-model="dialogEstado" max-width="650">
          <fuera-plazo
            :proyectoId="id"
            @saveFueraPlazo="dialogEstado = false"
          ></fuera-plazo>
        </v-dialog>

        <!--Asignar duración del proyecto -->
        <v-row>
          <v-col cols="12">
            <h3 class="mt-6">Duración del proyecto</h3>

            <v-card class="pa-6 mb-8" outlined>
              <v-row>
                <v-col cols="6">
                  <v-menu
                    ref="inicio"
                    v-model="menu"
                    :close-on-content-click="true"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fecha_inicio"
                        label="Fecha de inicio"
                        prepend-icon="mdi-calendar"
                        readonly
                        hide-details="true"
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fecha_inicio"
                      color="primary"
                      no-title
                      scrollable
                      locale="es"
                      hide-details="true"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu
                    ref="final"
                    :close-on-content-click="true"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fecha_final"
                        label="Fecha de final"
                        prepend-icon="mdi-calendar"
                        hide-details="true"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fecha_final"
                      color="primary"
                      no-title
                      scrollable
                      locale="es"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <!-- Asignar tareas -->
        <v-row>
          <v-col cols="12">
            <h3 class="my-0">Asignar tareas</h3>

            <v-card class="pa-6 mb-8" outlined>
              <div
                v-for="(item, index) in tareas"
                :key="index"
                :id="`tarea_${index}`"
              >
                <v-row>
                  <v-col cols="11" class="pb-0">
                    <v-select
                      v-if="_tipoTareas"
                      v-model="tareas[index].tipo_tarea_id"
                      name="tareas[][tipo_tarea_id]"
                      :items="_tipoTareas"
                      dense
                      label="Tarea"
                      outlined
                      item-text="nombre"
                      item-value="id"
                    ></v-select>
                  </v-col>

                  <v-col cols="1" class="pb-0">
                    <v-btn
                      color="white"
                      depressed
                      class="mr-2 delete"
                      dark
                      @click="_removeTarea(index)"
                    >
                      <v-icon small color="black">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>

              <v-btn
                color="black"
                class="my-2"
                dark
                small
                depressed
                @click="_addTarea()"
              >
                <v-icon small class="mr-1">
                  mdi-pencil
                </v-icon>
                Añadir tarea
              </v-btn>
            </v-card>
          </v-col>
        </v-row>

        <!-- Asignar técnicos -->
        <v-row>
          <v-col cols="12">
            <h3 class="my-0">Asignar técnicos</h3>

            <v-card class="pa-6 mb-8" outlined>
              <div
                v-for="(item, index) in tecnicos"
                :key="index"
                :id="`tecnico_${index}`"
              >
                <v-row>
                  <v-col cols="7" class="pb-0">
                    <v-select
                      v-if="_tecnicos"
                      v-model="tecnicos[index].usuario_id"
                      :items="_tecnicos"
                      label="Tecnico"
                      outlined
                      dense
                      item-text="nombre"
                      item-value="id"
                    ></v-select>
                  </v-col>

                  <v-col cols="4" class="pb-0">
                    <v-text-field
                      v-if="_tecnicos"
                      v-model="tecnicos[index].participacion"
                      label="Participación"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="1" class="pb-0">
                    <v-btn
                      color="white"
                      depressed
                      class="mr-2 delete"
                      dark
                      @click="_removeTecnico(index)"
                    >
                      <v-icon small color="black">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>

              <v-btn
                color="black"
                class="my-2"
                small
                dark
                depressed
                @click="_addTecnico()"
              >
                <v-icon small class="mr-1">
                  mdi-pencil
                </v-icon>
                Añadir técnico
              </v-btn>
            </v-card>
          </v-col>
        </v-row>

        <!-- Datos extra: descripción... -->

        <v-row>
          <v-col cols="12">
            <!-- fecha contacto -->
            <v-menu
              ref="fecha_contacto"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fecha_contacto"
                  label="Fecha de contacto con cliente"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fecha_contacto"
                color="primary"
                no-title
                scrollable
                locale="es"
              >
              </v-date-picker>
            </v-menu>
            <!-- fecha contacto -->

            <v-text-field
              outlined
              dense
              v-model="descripcion"
              label="Descripción"
            ></v-text-field>

            <v-textarea
              outlined
              dense
              v-model="observaciones"
              label="Observaciones"
            ></v-textarea>

            <v-text-field
              outlined
              dense
              v-model="entidad_certificadora"
              label="Entiad certificadora"
            ></v-text-field>

            <v-text-field
              outlined
              dense
              v-model="horas_asignadas"
              label="Horas asignadas"
            ></v-text-field>

            <v-text-field
              outlined
              dense
              v-model="importe_venta"
              label="Importe de venta"
            ></v-text-field>

            <v-text-field
              outlined
              dense
              v-model="subcontratacion"
              label="Subcontratación"
            ></v-text-field>

            <v-switch
              v-model="planificacion"
              label="Planificación del proyecto"
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-4 pb-6">
        <v-btn
          color="primary"
          class="px-4"
          large
          depressed
          @click="_sendMailQuestion()"
          :loading="loadingButton"
          :disabled="loadingButton"
        >
          <v-icon class="mr-2">
            mdi-cloud-upload
          </v-icon>
          Guardar Proyecto
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import FueraPlazo from "@/components/FueraPlazo";
export default {
  props: ["proyectoProp", "idProp"],
  components: {
    FueraPlazo,
  },
  data() {
    return {
      loading: true,
      loadingButton: false,
      loadingTareas: false,
      valid: false,
      sendMailDialog: false,
      send_mail: false,
      dialog: false,
      dialogEstado: false,
      prevTipoProyecto: null,
      menu: false,
      tareas: [],
      tarea: {
        id: null,
        proyecto_id: null,
        tipo_tarea_id: null,
        fecha_inicio: null,
        fecha_final: null,
        horas: null,
      },
      tecnicos: [],
      tecnico: {
        id: null,
        usuario_id: null,
        proyecto_id: null,
        participacion: null,
      },
      proyecto: null,
      id: null,
      codigo: null,
      tipo_proyecto_id: null,
      tipo_esquema_id: null,
      cliente_id: null,
      estado_id: null,
      fecha_contacto: null,
      descripcion: null,
      observaciones: null,
      entidad_certificadora: null,
      accion_formativa: null,
      fecha_inicio: null,
      fecha_final: null,
      horas_asignadas: null,
      importe_venta: null,
      subcontratacion: null,
      planificacion: null,
    };
  },
  computed: {
    ...mapState({
      _tipoTareas: "tipoTareas",
      _tipoProyectos: "tipoProyectos",
      _tipoEsquemas: "tipoEsquemas",
      _estados: "tipoEstados",
      _clientes: "clientes",
      _tecnicos: "tecnicos",
    }),
    _clientesComputed() {
      return this._clientes.filter((res, index) => {
        if (index > 0) return res;
      });
    },
    _estadosComputed() {
      return this._estados.filter((res) => {
        // if (index > 0) return res;
        return res;
      });
    },
  },
  watch: {
    proyectoProp(val) {
      if (val != "nuevo") this._loadProyecto(val);
      this.loading = false;
    },
    _tipoTareas(val) {
      if (val && !this.idProp) this.loading = false;
    },
    tipo_proyecto_id(val, old) {
      this.prevTipoProyecto = old;
    },
    estado_id(val) {
      if (val == 2) {
        this.dialogEstado = true;
      }
    },
  },
  methods: {
    _loadProyecto(val) {
      if (val) {
        this.id = val.id;
        this.tareas = val.tareas;
        this.tecnicos = val.tecnicos;
        this.codigo = val.codigo;
        this.tipo_proyecto_id = val.tipo_proyecto_id;
        this.tipo_esquema_id = val.tipo_esquema_id;
        this.cliente_id = val.cliente_id;
        this.estado_id = val.estado_id;
        this.fecha_contacto = val.fecha_contacto;
        this.descripcion = val.descripcion;
        this.observaciones = val.observaciones;
        this.entidad_certificadora = val.entidad_certificadora;
        this.accion_formativa = val.accion_formativa;
        this.fecha_inicio = val.fecha_inicio;
        this.fecha_final = val.fecha_final;
        this.horas_asignadas = val.horas_asignadas;
        this.importe_venta = val.importe_venta;
        this.subcontratacion = val.subcontratacion;
        this.planificacion = val.planificacion;
      }
    },
    _sendMailQuestion() {
      this.sendMailDialog = true;
    },
    _saveProyecto() {
      this.loadingButton = true;
      let sumaPorcentaje = 0;
      this.tecnicos.forEach((tecnico) => {
        if (!tecnico.del)
          sumaPorcentaje = sumaPorcentaje + parseInt(tecnico.participacion);
      });

      if (sumaPorcentaje != 100) {
        let snackbar = {
          status: true,
          message: "La suma de la participación de los técnicos debe dar 100.",
          type: "error",
        };
        this.$store.commit("_SNACKBAR", snackbar);
        this.loadingButton = false;
        return true;
      }

      let params = {
        id: this.id,
        send_mail: this.send_mail,
        codigo: this.codigo,
        tipo_proyecto_id: this.tipo_proyecto_id,
        tipo_esquema_id: this.tipo_esquema_id,
        cliente_id: this.cliente_id,
        estado_id: this.estado_id,
        fecha_contacto: this.fecha_contacto,
        descripcion: this.descripcion,
        observaciones: this.observaciones,
        entidad_certificadora: this.entidad_certificadora,
        accion_formativa: this.accion_formativa,
        fecha_inicio: this.fecha_inicio,
        fecha_final: this.fecha_final,
        horas_asignadas: this.horas_asignadas,
        importe_venta: this.importe_venta,
        subcontratacion: this.subcontratacion,
        planificacion: this.planificacion,
        tareas: this.tareas,
        tecnicos: this.tecnicos,
      };

      axios.post("proyectos", params).then((res) => {
        if (res.data.status_code != 200) {
          let snackbar = {
            status: true,
            message: res.data.data,
            type: "error",
          };
          this.$store.commit("_SNACKBAR", snackbar);
        } else {
          this._loadProyecto(res.data.data);

          let snackbar = {
            status: true,
            message: "El proyecto se ha guardado correctamente.",
            type: "success",
          };
          this.$store.commit("_SNACKBAR", snackbar);
        }
        // Poner capas visibles
        this.tareas.forEach((item, i) => {
          document.getElementById(`tarea_${i}`).style.display = "block";
        });
        this.tecnicos.forEach((item, i) => {
          document.getElementById(`tecnico_${i}`).style.display = "block";
        });
        this.loadingButton = false;
        this.sendMailDialog = false;
        this.send_mail = false;
      });
    },
    _getTipoTareas() {
      this.loadingTareas = true;
      axios.get(`tipo-proyectos/${this.tipo_proyecto_id}`).then((res) => {
        if (res.data.status_code == 200) {
          this.tareas.forEach((tarea, index) => {
            if (
              !res.data.data.tipo_tareas.some(
                (e) => e.tipo_tarea_id == tarea.tipo_tarea_id
              )
            ) {
              this._removeTarea(index);
            } else {
              this._removeTareaInverse(index);
            }
          });

          res.data.data.tipo_tareas.forEach((tarea) => {
            if (
              !this.tareas.some((e) => e.tipo_tarea_id == tarea.tipo_tarea_id)
            ) {
              this._addTarea(tarea.tipo_tarea_id);
            }
          });
        }
        this.dialog = false;
        this.loadingTareas = false;
      });
    },
    _addTarea(tipoTareaId = null) {
      this.tarea = {
        proyecto_id: this.id,
        tipo_tarea_id: tipoTareaId,
        fecha_inicio: null,
        fecha_final: null,
        horas: null,
      };
      this.tareas.push(this.tarea);
    },
    _removeTarea(el) {
      this.tareas.map((item, i) => {
        if (i == el) {
          if (item.id) {
            item["del"] = item.id;
            document.getElementById(`tarea_${el}`).style.display = "none";
          } else {
            this.tareas.splice(i, 1);
          }
        }
      });
    },
    _removeTareaInverse(el) {
      this.tareas.map((item, i) => {
        if (i == el) {
          if (item.id) {
            item["del"] = null;
            document.getElementById(`tarea_${el}`).style.display = "block";
          }
        }
      });
    },
    _cancelDialog() {
      this.tipo_proyecto_id = this.prevTipoProyecto;
      this.dialog = false;
    },

    _addTecnico() {
      this.tecnico = {
        proyecto_id: this.id,
        usuario_id: null,
        participacion: null,
      };
      this.tecnicos.push(this.tecnico);
    },
    _removeTecnico(el) {
      this.tecnicos.map((item, i) => {
        if (i == el) {
          if (item.id) {
            item["del"] = item.id;
            document.getElementById(`tecnico_${el}`).style.display = "none";
          } else {
            this.tecnicos.splice(i, 1);
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
.delete {
}
</style>
