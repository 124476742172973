<template>
  <div class="proyectos">
    <h1>Proyectos</h1>

    <!-- accion para borrar -->
    <v-dialog v-model="borrarDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="text-h5">
          Borrar proyecto
        </v-card-title>
        <v-card-text
          >¿Estás seguro de eliminar el proyecto? No se podrán recuperar los
          datos.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="borrarDialog = false">
            No
          </v-btn>
          <v-btn color="primary" text @click="_deleteComplete()">
            Sí
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-btn
      color="primary"
      class="my-3 mb-6"
      dark
      depressed
      to="/editar/proyecto"
    >
      <v-icon class="mr-1">
        mdi-plus
      </v-icon>
      Nuevo Proyecto
    </v-btn>

    <v-btn
      color="primary darken-2"
      class="my-3 ml-3 mb-6"
      dark
      depressed
      to="/datos"
    >
      <v-icon class="mr-1">
        mdi-pencil
      </v-icon>
      Tareas por Tipo Proyecto
    </v-btn>


    <v-toolbar class="mb-2" color="white" flat>
      <v-text-field
        v-model="busqueda"
        label="Buscar por cliente"
        dense
        hide-details="true"
        outlined
        required
      ></v-text-field>

      <v-spacer></v-spacer>
    </v-toolbar>

    <v-chip
    v-for="item in clientes" :key="item.id"
      label
      class="ma-1"
      outlined
      pill
      @click="_setCliente(item)"
    >
      {{ item.nombre_comercial}}
    </v-chip>




    <v-toolbar class="mb-2" color="white" flat>
      <v-select
        v-if="_estados"
        v-model="estado_id"
        :items="_estados"
        label="Estados"
        @change="_loadProyectos()"
        clearable
        flat
        solo
        hide-details
        dense
        item-text="nombre"
        item-value="id"
      ></v-select>
      <v-spacer></v-spacer>

      <!-- <v-select
        v-if="_clientes"
        v-model="cliente_id"
        :items="_clientes"
        label="Clientes"
        @change="_loadProyectos()"
        clearable
        flat
        solo
        hide-details
        dense
        item-text="nombre_comercial"
        item-value="id"
      ></v-select> -->
      <v-spacer></v-spacer>
      <v-select
        v-if="_tecnicos"
        v-model="tecnico_id"
        :items="_tecnicos"
        label="Técnicos"
        @change="_loadProyectos()"
        dense
        clearable
        flat
        solo
        hide-details
        item-text="nombre"
        item-value="id"
      ></v-select>

      <v-spacer></v-spacer>
      <v-select
        v-model="sortData"
        :items="sortDataOptions"
        label="Ordenar"
        @change="_loadProyectos()"
        dense
        clearable
        flat
        solo
        hide-details
        item-text="name"
        item-value="val"
      ></v-select>

      <v-spacer></v-spacer>
    </v-toolbar>

    <v-card class="elevation-0">
      <v-data-table
        :headers="headers"
        :items="proyectos"
        class="elevation-0"
        :loading="loading"
        loading-text="Cargando proyectos..."
        hide-default-footer
        disable-pagination
      >
        <template v-slot:item.codigo="{ item }">
          <div
            class="cod_button"
            @click="$router.push(`/proyectos/${item.id}`)"
          >
            {{ item.codigo }}
          </div>
        </template>

        <template v-slot:item.estado="{ item }">
          <v-chip
            small
            label
            class="my-2"
            :color="_getChipEstado(item.estado.nombre)"
            outlined
            pill
          >
            {{ item.estado.nombre }}
          </v-chip>
        </template>

        <template v-slot:item.descripcion="{ item }">
          <div class="py-3" v-if="item.descripcion">
            {{ _limitWords(item.descripcion, 10) }}
          </div>
        </template>

        <template v-slot:item.acciones="{ item }">
          <div class="table-buttons">
            <v-btn
              color="primary"
              depressed
              class="mr-2"
              small
              dark
              :to="`/editar/proyecto/${item.id}`"
            >
              <v-icon small>
                mdi-pencil
              </v-icon>
            </v-btn>

            <v-btn
              color="error"
              depressed
              class="ml-2"
              small
              outlined
              v-if="item.id"
              @click="_delete(item.id)"
            >
              <v-icon small>
                mdi-delete
              </v-icon>
            </v-btn>
          </div>
        </template>

        <template v-slot:no-data>
          <v-alert text type="info" class="text-left my-2" tile>
            No hay datos que mostrar.
          </v-alert>
        </template>
      </v-data-table>
    </v-card>

    <div class="mt-3 text-right">
      <v-pagination
        :length="pagination"
        v-model="page"
        class="elevation-0"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  data() {
    return {
      loading: true,
      page: 1,
      pagination: null,
      sortData: "DESC",
      sortDataOptions: [
        {
          val: "DESC",
          name: "Más Recientes",
        },
        {
          val: "ASC",
          name: "Más antiguos",
        },
      ],
      headers: [
        {
          text: "Código",
          align: "start",
          sortable: false,
          value: "codigo",
        },
        { text: "Estado", value: "estado" },
        { text: "Descripción", value: "descripcion", width: "30%" },
        { text: "Cliente", value: "cliente.nombre_comercial" },
        { text: "Fecha inicio", value: "fecha_inicio" },
        { text: "Fecha final", value: "fecha_final" },
        { text: "Acciones", value: "acciones" },
      ],
      proyectos: [],
      loadProyectos: false,
      estado_id: 1,
      clientes: [],
      busqueda: null,
      cliente_id: null,
      tecnico_id: null,
      borrarDialog: false,
      deleteItem: false,
    };
  },
  mounted() {
    this._loadProyectos();
  },
  watch: {
    page() {
      this._loadProyectos();
    },
    busqueda(val) {

      if(val.length == 0 ){
        this.cliente_id = null;
        this._loadProyectos()
      }

      if (val.length > 0 && val.length < 3) {
        this.clientes = []
        return;
      }
      this._searchCliente()
    },
  },
  computed: {
    ...mapState({
      _estados: "tipoEstados",
      _clientes: "clientes",
      _tecnicos: "tecnicos",
    }),
  },
  methods: {
    _setCliente(val) {
      this.busqueda = val.nombre_comercial
      this.cliente_id = val.id
      this._loadProyectos();
    },  
    _searchCliente() {
      axios.get(`/clientes/buscar/${this.busqueda}`).then((res) => {
        if (res.data.status_code == 200) {
          this.clientes = res.data.data;
        }
      });
    },
    _loadProyectos() {
      this.loading = true;
      this.proyectos = [];
      let params = {
        params: {
          page: this.page,
          estado_id: this.estado_id,
          cliente_id: this.cliente_id,
          tecnico_id: this.tecnico_id,
          pagination: 30,
          sort: this.sortData,
        },
      };

      axios.get(`/proyectos`, params).then((res) => {
        if (res.data.status_code == 200) {
          this.proyectos = res.data.data.data;
          this.pagination = res.data.data.last_page;
        }
        this.loading = false;
        this.clientes = [];
      });
    },
    _getChipEstado(estado) {
      let color = "success";
      switch (estado) {
        case "Abierto":
          color = "success";
          break;
        case "Cerrado":
          color = "error";
          break;
        case "Abierto Fuera de Plazo":
          color = "warning";
          break;
        case "Inactivo":
          color = "grey";
          break;
      }
      return color;
    },
    _delete(id) {
      this.borrarDialog = true;
      this.deleteItem = id;
    },
    _deleteComplete() {
      this.loading = true;
      if (!this.deleteItem) {
        this.borrarDialog = false;
        return true;
      }
      axios.delete(`/proyectos/${this.deleteItem}`).then((res) => {
        if (res.data.status_code == 200) {
          let del = res.data.data;

          if (del) {
            this._loadProyectos(del);
            this.$store.dispatch("_proyectos");
            let snackbar = {
              status: true,
              message: "El proyecto se ha borrado correctamente.",
              type: "success",
            };

            this.$store.commit("_SNACKBAR", snackbar);
          }
        }
        this.loading = false;
        this.borrarDialog = false;
      });
    },
    _limitWords(textToLimit, wordLimit = 2, suffix = "...") {
      var finalText = "";
      var text2 = textToLimit.replace(/\s+/g, " ");
      var text3 = text2.split(" ");
      var numberOfWords = text3.length;
      var i = 0;
      if (numberOfWords > wordLimit) {
        for (i = 0; i < wordLimit; i++) {
          finalText = finalText + " " + text3[i] + " ";
        }
        return finalText + suffix;
      } else {
        return textToLimit;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cod_button {
  cursor: pointer;
  &:hover {
    color: #1976d5;
  }
}

.table-buttons {
  min-width: 160px;
}
</style>
