<template>
  <div>
    <div class="text-center loader" v-if="loading">
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </div>

    <v-card tile class="elevation-0" v-else>
      <v-card-title>
        <h2 v-if="id">Editar cliente</h2>
        <h2 v-else>Nuevo cliente</h2>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field
              v-model="nombre_comercial"
              label="Nombre comercial"
              hide-details="true"
              outlined
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field
              v-model="razon_social"
              label="Razón social"
              dense
              hide-details="true"
              outlined
              required
            ></v-text-field>
          </v-col>

          <!-- Asignar contacto -->
          <v-col cols="12" md="12">
            <h3 class="my-0">Contactos</h3>
            <v-card class="pa-6 mb-3" outlined>
              <div
                v-for="(item, index) in contactos"
                :key="index"
                :id="`año_${index}`"
              >
                <v-row>
                  <v-col v-if="index > 0" cols="12" class="my-2"
                    ><v-divider></v-divider
                  ></v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="contactos[index].nombre"
                      label="Contacto"
                      dense
                      hide-details="true"
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="contactos[index].email"
                      label="Email"
                      dense
                      hide-details="true"
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="contactos[index].telefono"
                      label="Teléfono"
                      dense
                      hide-details="true"
                      outlined
                      required
                    ></v-text-field>

                    <v-btn
                      color="red"
                      depressed
                      class="delete my-3"
                      dark
                      small
                      @click="_removeContacto(index)"
                    >
                      <v-icon small>
                        mdi-delete
                      </v-icon>
                      Eliminar contacto
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="contactos[index].movil"
                      label="Móvil"
                      dense
                      hide-details="true"
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>

              <v-divider class="mt-3"></v-divider>

              <v-btn
                color="black"
                class="my-2"
                dark
                small
                depressed
                @click="_addContacto()"
              >
                <v-icon small class="mr-1">
                  mdi-plus
                </v-icon>
                Añadir Contacto
              </v-btn>
            </v-card>
          </v-col>
          <!-- Asignar contacto -->

          <v-col cols="12" md="6">
            <v-text-field
              v-model="direccion"
              label="Dirección"
              dense
              hide-details="true"
              outlined
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="cp"
              label="Código postal"
              dense
              hide-details="true"
              outlined
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="localidad"
              label="Localidad"
              dense
              hide-details="true"
              outlined
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="provincia"
              label="Provincia"
              dense
              hide-details="true"
              outlined
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field
              v-model="plantilla"
              label="Plantilla"
              dense
              hide-details="true"
              outlined
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-4 pb-6">
        <v-btn
          color="primary"
          class="px-4"
          large
          depressed
          @click="_saveCliente()"
          :loading="loadingButton"
          :disabled="loadingButton"
        >
          <v-icon class="mr-2">
            mdi-cloud-upload
          </v-icon>
          Guardar Cliente
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  props: ["clienteProp"],
  data() {
    return {
      loading: true,
      loadingButton: false,
      id: null,
      razon_social: null,
      nombre_comercial: null,
      direccion: null,
      cp: null,
      localidad: null,
      provincia: null,
      contactos: [],
      contacto: null,
      plantilla: null,
      error: false,
    };
  },
  mounted() {
    if (!this.contactos.length) this._addContacto();
  },
  computed: {
    ...mapState({
      _auth: "auth",
    }),
  },
  watch: {
    clienteProp(val) {
      if (val != "nuevo") this._loadCliente(val);
      this.loading = false;
    },
  },
  methods: {
    _loadCliente(val) {
      if (val) {
        this.id = val.id;
        this.nombre_comercial = val.nombre_comercial;
        this.razon_social = val.razon_social;
        this.direccion = val.direccion;
        this.cp = val.cp;
        this.localidad = val.localidad;
        this.provincia = val.provincia;
        this.contactos = JSON.parse(val.contactos);
        this.plantilla = val.plantilla;
        this.error = false;
      }
    },
    _addContacto() {
      this.contacto = {
        nombre: null,
        telefono: null,
        movil: null,
        email: null,
      };
      this.contactos.push(this.contacto);
    },
    _removeContacto(el) {
      this.contactos.map((i, index) => {
        if (index == el) {
          this.contactos.splice(index, 1);
        }
      });
    },
    _saveCliente() {
      this.loadingButton = true;
      this.error = false;

      let params = {
        id: this.id,
        razon_social: this.razon_social,
        nombre_comercial: this.nombre_comercial,
        direccion: this.direccion,
        cp: this.cp,
        localidad: this.localidad,
        provincia: this.provincia,
        plantilla: this.plantilla,
        contactos: JSON.stringify(this.contactos),
      };

      axios.post(`clientes`, params).then((res) => {
        let snackbar = null;
        if (res.data.status_code != 200) {
          let errors = res.data.data;
          snackbar = {
            status: true,
            message: errors,
            type: "error",
          };
        } else {
          this.$store.dispatch("_clientes");

          snackbar = {
            status: true,
            message: "Los datos del cliente se han guardado correctamente.",
            type: "success",
          };
        }

        this.$store.commit("_SNACKBAR", snackbar);
        this.loadingButton = false;
      });
    },
  },
};
</script>
