<template>
  <div class="tecnicos">
    <h1>Técnicos</h1>

    <!-- accion para borrar -->
    <v-dialog v-model="borrarDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="text-h5">
          Borrar usuario
        </v-card-title>
        <v-card-text
          >¿Estás seguro de eliminar el usuario? No se podrán recuperar los
          datos.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="borrarDialog = false">
            No
          </v-btn>
          <v-btn color="primary" text @click="_deleteComplete()">
            Sí
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-btn
      color="primary"
      class="my-3 mb-6"
      dark
      depressed
      to="/editar/tecnico"
    >
      <v-icon class="mr-1">
        mdi-plus
      </v-icon>
      Nuevo Técnico
    </v-btn>

    <v-alert icon="mdi-account" text type="info">
      Crea un usuario administrador o un técnico.
    </v-alert>

    <v-card class="elevation-0">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar técnico"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="tecnicos"
        class="elevation-0"
        :search="search"
        :loading="loading"
        loading-text="Cargando técnicos..."
        :footer-props="{
          'items-per-page-text': 'Mostrar',
        }"
      >
        <template v-slot:footer.page-text="items">
          {{ items.pageStart }} - {{ items.pageStop }} de
          {{ items.itemsLength }}
        </template>

        <template v-slot:item.es_admin="{ item }">
          <v-chip color="dark lighten-1" v-if="item.es_admin" light label>
            <v-icon>mdi-shield-lock-outline</v-icon> Administrador
          </v-chip>
        </template>

        <template v-slot:item.acciones="{ item }">
          <v-btn
            v-if="!item.es_admin || _auth.id == item.id"
            color="primary"
            small
            depressed
            class="mr-2"
            dark
            :to="`/editar/tecnico/${item.id}`"
          >
            <v-icon small>
              mdi-pencil
            </v-icon>
          </v-btn>

          <v-btn
            color="dark"
            class="mr-2"
            small
            depressed
            dark
            :to="`/tecnicos/${item.id}`"
          >
            <v-icon small>
              mdi-eye
            </v-icon>
          </v-btn>

          <v-btn
            v-if="!item.es_admin || _auth.id == item.id"
            color="error"
            depressed
            class="mr-2"
            small
            outlined
            @click="_delete(item.id)"
          >
            <v-icon small>
              mdi-delete
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:no-data>
          <v-alert text type="info" class="text-left my-2" tile>
            No hay datos que mostrar.
          </v-alert>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  data() {
    return {
      loading: true,
      search: "",
      borrarDialog: false,
      deleteItem: false,
      headers: [
        {
          text: "Código",
          align: "start",
          sortable: false,
          value: "codigo",
        },
        { text: "Permisos", value: "es_admin" },
        { text: "Nombre", value: "nombre" },
        { text: "Email", value: "email" },
        { text: "Acciones", value: "acciones" },
      ],
      tecnicos: [],
    };
  },
  computed: {
    ...mapState({
      _auth: "auth",
    }),
  },
  mounted() {
    this._loadTecnicos(null);
  },
  methods: {
    _loadTecnicos(del) {
      this.loading = true;
      if (!del) this.tecnicos = [];
      axios.get("/tecnicos").then((res) => {
        if (res.data.status_code == 200) {
          this.tecnicos = res.data.data;
        }
        this.loading = false;
      });
    },
    _delete(id) {
      this.borrarDialog = true;
      this.deleteItem = id;
    },
    _deleteComplete() {
      this.loading = true;
      if (!this.deleteItem) {
        this.borrarDialog = false;
        return true;
      }
      this.loading = true;
      axios.delete(`/usuarios/${this.deleteItem}`).then((res) => {
        if (res.data.status_code == 200) {
          let del = res.data.data;

          if (del) {
            this._loadTecnicos(del);
            this.$store.dispatch("_clientes");
            let snackbar = {
              status: true,
              message: "El técnico se ha borrado correctamente.",
              type: "success",
            };

            this.$store.commit("_SNACKBAR", snackbar);
          }
        }
        this.borrarDialog = false;
        this.loading = false;
      });
    },
  },
};
</script>
