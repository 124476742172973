<template>
  <div class="tecnicos">
    <div class="text-center loader" v-if="loading">
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </div>

    <v-card tile class="elevation-0" v-else>
      <v-card-title>
        <h3>{{ compararActividades[0].nombre }}</h3>
        <v-chip v-if="compararActividades[0].es_admin" class="ma-2" label>
          Administrador
        </v-chip>
        <v-chip v-else class="ma-2" label> Técnico </v-chip>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col lg="4" cols="sm" class="mt-4 pb-2">
              <v-card tile class="elevation-0" v-if="compararProyectos">
                <v-row class="no-gutters">
                  <div class="col-auto">
                    <div class="primary fill-height" style="width: 5px;"></div>
                  </div>
                  <div class="col pa-3 py-4 primary--text">
                    <h5 class="text-truncate text-uppercase mb-3">
                      Nº Proyectos por estado
                    </h5>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              Estado
                            </th>
                            <th class="text-left">
                              Cantidad
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in _estadosComputed" :key="item.id">
                            <td>{{ item.nombre }}</td>
                            <td>{{ _countEstados(item.id) }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </v-row>
              </v-card>
            </v-col>

            <v-col lg="8" cols="sm" class="mt-4 pb-2">
              <v-card tile class="elevation-0" v-if="compararProyectos">
                <v-row class="no-gutters">
                  <div class="col-auto">
                    <div class="primary fill-height" style="width: 5px;"></div>
                  </div>
                  <div class="col pa-3 py-4 primary--text">
                    <h5 class="text-truncate text-uppercase mb-3">
                      Nº Proyectos por Tipo, horas Asignadas y Horas Reales
                    </h5>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              Tipo
                            </th>
                            <th class="text-left">
                              Cantidad
                            </th>
                            <th class="text-left">
                              Horas asignadas
                            </th>
                            <th class="text-left">
                              Horas reales
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in _tipoProyectos" :key="item.id">
                            <td>{{ item.nombre }}</td>
                            <td>{{ _countTipo(item).count }}</td>
                            <td>{{ _countTipo(item).horas_asignadas }}</td>
                            <td>{{ _countTipo(item).horas_reales }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapState } from "vuex";
export default {
  components: {},
  data: () => ({
    loading: true,
    id: null,
    tecnico: null,
    compararProyectos: [],
    compararActividades: [],
  }),
  mounted() {
    this.loading = true;
    this.id = this.$route.params.id;
    axios.get(`/datos-tecnico/${this.id}`).then((res) => {
      if (res.data.status_code == 200) {
        this.compararActividades = res.data.data[0];
        this.compararProyectos = res.data.data[1];
      }
      this.loading = false;
    });
  },
  computed: {
    ...mapState({
      _estados: "tipoEstados",
      _tipoProyectos: "tipoProyectos",
      _tecnicos: "tecnicos",
      _datosAdministrativos: "datosAdministrativos",
    }),
    _tecnicosComputed() {
      return this._tecnicos.filter((res, index) => {
        if (index > 0) return res;
      });
    },
    _estadosComputed() {
      return this._estados.filter((res, index) => {
        if (index > 0) return res;
      });
    },
  },
  methods: {
    _countEstados(estado_id) {
      let count = 0;
      this.compararProyectos.forEach((res) => {
        if (res.proyecto.estado_id == estado_id) {
          count++;
        }
      });
      return count;
    },
    _countTipo(tipo) {
      let count = 0;
      let horas_asignadas = 0;
      let horas_reales = moment.duration("00:00");
      this.compararProyectos.forEach((res) => {
        if (res.proyecto.tipo_proyecto_id == tipo.id) {
          count++;
          horas_asignadas =
            horas_asignadas +
            parseInt((res.proyecto.horas_asignadas * res.participacion) / 100);

          res.proyecto.actividades.forEach((act) => {
            if (act.usuario_id != this.id) return;
            var startTime = moment(act.hora_inicio, "HH:mm");
            var endTime = moment(act.hora_final, "HH:mm");
            // calculate total duration
            var duration = moment.duration(endTime.diff(startTime));
            // duration in hours
            var hours = parseInt(duration.asHours());
            // duration in minutes
            var minutes = parseInt(duration.asMinutes()) % 60;
            horas_reales.add(moment.duration(`${hours}:${minutes}`));
          });
        }
      });
      return {
        count,
        horas_asignadas,
        horas_reales: moment.duration(horas_reales).asHours(),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.crono-col-container {
  display: flex;
  align-items: stretch;
  justify-items: flex-start;
  flex-wrap: nowrap; //wrap
  justify-content: flex-start;
  overflow-x: scroll;
  width: 100%;
}

.control-container {
  display: flex;
  &-div {
    width: 100%;
  }
}

.v-card {
  height: 100%;
}
</style>
