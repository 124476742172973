<template>
  <v-row>
    <v-col cols="12">
      <v-card class="elevation-0">
        <v-card-title>
          <h2>Actividades</h2>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="actividades"
          :loading="loading"
          loading-text="Cargando actividades..."
          class="elevation-0"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:item.tarea="{ item }">
            <v-chip
              small
              color="grey lighten-3"
              label
              light
              class="text-center"
            >
              <strong>{{ item.tarea.tipo_tarea.nombre }}</strong>
            </v-chip>
          </template>

          <template v-slot:item.tecnico="{ item }">
            {{ item.usuario.nombre }}
          </template>

          <template v-slot:item.horas="{ item }">
            {{ _formatFloat(_setHours(item.hora_inicio, item.hora_final)) }}
            <v-chip
              small
              color="grey lighten-3"
              label
              light
              class="text-center ml-2"
            >
              {{ item.hora_inicio }} - {{ item.hora_final }}
            </v-chip>
          </template>

          <template v-slot:item.archivos="{ item }">
            <v-btn
              v-if="item.archivos.length"
              color="dark"
              small
              outlined
              target="_blank"
              @click="_setArchivosDeActividad(item.archivos)"
            >
              <v-icon light small>mdi-file-download</v-icon>
            </v-btn>
          </template>

          <template v-slot:item.acciones="{ item }">
            <v-btn
              color="primary"
              class="mr-2"
              dark
              small
              depressed
              v-if="_auth.id == item.usuario_id"
              :to="`/editar/actividad/${proyecto.id}/${item.id}`"
            >
              <v-icon small>
                mdi-pencil
              </v-icon>
            </v-btn>

            <v-btn
              color="error"
              depressed
              class="mr-2"
              small
              outlined
              v-if="_auth.id == item.usuario_id"
              @click="_delete(proyecto.id, item.id)"
            >
              <v-icon small>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>

      <div class="mt-3 text-right">
        <v-pagination
          :length="pagination"
          v-model="page"
          class="elevation-0"
        ></v-pagination>
      </div>

      <v-dialog v-model="dialog" max-width="500">
        <v-card>
          <v-card-title class="text-h6">
            Archivos de esta actividad
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text>
            <v-list>
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(archivo, i) in archivos"
                  :key="i"
                  :href="`${apiUrl}archivos/pdf/${archivo.nombre}`"
                  target="_blank"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-file-document-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      <template v-if="archivo.nombre_original">
                        {{ archivo.nombre_original }}
                      </template>
                      <template v-else>
                        {{ archivo.name }}
                      </template>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapState } from "vuex";
export default {
  name: "Actividades",
  props: ["proyecto"],
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_SERVER,
      loading: true,
      dialog: false,
      page: 1,
      pagination: null,
      actividades: [],
      archivos: null,
      headers: [
        { text: "Tarea", value: "tarea" },
        { text: "Técnico", value: "tecnico" },
        { text: "Fecha", value: "fecha" },
        { text: "Horas", value: "horas" },
        { text: "Archivos", value: "archivos" },
        { text: "Acciones", value: "acciones" },
      ],
    };
  },
  mounted() {
    this._loadActividades();
  },
  watch: {
    page() {
      this._loadActividades();
    },
  },
  computed: {
    ...mapState({
      _auth: "auth",
    }),
  },
  methods: {
    _loadActividades(del) {
      this.loading = true;
      if (!del) this.actividades = [];
      let params = {
        params: {
          page: this.page,
          proyecto_id: this.proyecto.id,
        },
      };
      axios.get(`/actividades`, params).then((res) => {
        if (res.data.status_code == 200) {
          this.actividades = res.data.data.data;
          this.pagination = res.data.data.last_page;
          this.loading = false;
        }
      });
    },
    _setArchivosDeActividad(e) {
      this.archivos = e;
      this.dialog = !this.dialog;
    },
    _setHours(inicio, final) {
      var startTime = moment(inicio, "HH:mm");
      var endTime = moment(final, "HH:mm");
      // calculate total duration
      var duration = moment.duration(endTime.diff(startTime));
      // duration in hours
      var hours = parseInt(duration.asHours());
      // duration in minutes
      var minutes = parseInt(duration.asMinutes()) % 60;
      return moment.duration(moment.duration(`${hours}:${minutes}`)).asHours();
    },
    _delete(proyecto_id, id) {
      this.loading = true;
      axios.delete(`/actividad/${id}`).then((res) => {
        if (res.data.status_code == 200) {
          let del = res.data.data;

          if (del) {
            this._loadActividades(del);
            this.$emit("delete", proyecto_id);
            let snackbar = {
              status: true,
              message: "La actividad se ha borrado correctamente.",
              type: "success",
            };

            this.$store.commit("_SNACKBAR", snackbar);
          }
        }
        this.loading = false;
      });
    },
    _formatFloat(val) {
      if (!val) return "0,00";

      if (isNaN(val)) return "-";
      return (val * 1)
        .toFixed(2) // always two decimal digits
        .replace(".", ",") // replace decimal point character with ,
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    },
  },
};
</script>
