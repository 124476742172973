<template>
  <div class="controles">
    <!-- Repeater técnicos -->
    <v-card class="elevation-0">
      <v-card-title>
        <h2>Sobre un Técnico</h2>
        <v-chip class="ml-3 text-uppercase" label>Datos de un técnico</v-chip>
      </v-card-title>

      <v-card-text class="pb-0">
        <v-row>
          <v-col cols="12" class="pb-0 mb-6">
            <v-select
              v-if="_tecnicos"
              v-model="tecnicoSelected"
              :items="_tecnicosComputed"
              hide-details="true"
              label="Tecnico"
              outlined
              dense
              item-text="nombre"
              item-value="id"
              prepend-icon="mdi-account"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="pb-0 mt-0">
        <v-row class="mb-0">
          <v-col cols="6">
            <v-menu
              ref="desde"
              v-model="menu"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="desde"
                  label="Desde"
                  prepend-icon="mdi-calendar"
                  readonly
                  hide-details="true"
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="desde"
                color="primary"
                no-title
                scrollable
                locale="es"
                hide-details="true"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-menu
              ref="hasta"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="hasta"
                  label="Hasta"
                  prepend-icon="mdi-calendar"
                  hide-details="true"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="hasta"
                color="primary"
                no-title
                scrollable
                locale="es"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="px-0 pb-6">
        <v-btn
          color="primary"
          class="px-8 ml-12"
          large
          depressed
          @click="_get()"
          :loading="loadingButton"
          :disabled="loadingButton"
        >
          <v-icon class="mr-2">
            mdi-eye
          </v-icon>
          Comparar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-row v-if="comprobacion">
      <v-col lg="12" cols="sm" class="mt-4 pb-2">
        <v-card tile class="elevation-0" v-if="compararProyectos">
          <v-row class="no-gutters">
            <div class="col-auto">
              <div class="primary fill-height" style="width: 5px;"></div>
            </div>
            <div class="col pa-3 py-4 primary--text">
              <h5 class="text-truncate text-uppercase mb-3">
                Carga horaria
              </h5>

              <v-list dense class="ma-0">
                <v-list-item-content>
                  <v-list-item-title>
                    Horas Totales Reales
                  </v-list-item-title>
                  <h4>{{ _formatFloat(_getReal()) }}</h4>
                </v-list-item-content>

                <v-list-item-content>
                  <v-list-item-title>
                    Horas Totales Asignadas
                  </v-list-item-title>
                  <h4>{{ _formatFloat(_getPlan()) }}</h4>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title>
                    Rentabilidad técnica
                  </v-list-item-title>
                  <h4>{{ _formatFloat(_rentabilidadTecnica()) }} %</h4>
                </v-list-item-content>

                <v-list-item-content>
                  <v-list-item-title>
                    Rentabilidad real
                  </v-list-item-title>
                  <h4>{{ _formatFloat(_getRentabilidadReal()) }} %</h4>
                </v-list-item-content>
              </v-list>
            </div>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="comprobacion">
      <v-col lg="4" cols="sm" class="mt-4 pb-2">
        <v-card tile class="elevation-0" v-if="compararProyectos">
          <v-row class="no-gutters">
            <div class="col-auto">
              <div class="primary fill-height" style="width: 5px;"></div>
            </div>
            <div class="col pa-3 py-4 primary--text">
              <h5 class="text-truncate text-uppercase mb-3">
                Nº Proyectos por estado
              </h5>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Estado
                      </th>
                      <th class="text-left">
                        Cantidad
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in _estadosComputed" :key="item.id">
                      <td>{{ item.nombre }}</td>
                      <td>{{ _countEstados(item.id) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-row>
        </v-card>
      </v-col>

      <v-col lg="8" cols="sm" class="mt-4 pb-2">
        <v-card tile class="elevation-0" v-if="compararProyectos">
          <v-row class="no-gutters">
            <div class="col-auto">
              <div class="primary fill-height" style="width: 5px;"></div>
            </div>
            <div class="col pa-3 py-4 primary--text">
              <h5 class="text-truncate text-uppercase mb-3">
                Nº Proyectos por Tipo, horas Asignadas y Horas Reales
              </h5>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Tipo
                      </th>
                      <th class="text-left">
                        Cantidad
                      </th>
                      <th class="text-left">
                        Horas asignadas
                      </th>
                      <th class="text-left">
                        Horas reales
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in _tipoProyectos" :key="item.id">
                      <td>{{ item.nombre }}</td>
                      <td>{{ _formatFloat(_countTipo(item).count) }}</td>
                      <td>
                        {{ _formatFloat(_countTipo(item).horas_asignadas) }}
                      </td>
                      <td>{{ _formatFloat(_countTipo(item).horas_reales) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapState } from "vuex";
export default {
  name: "Técnico",
  data() {
    return {
      loading: true,
      loadingButton: false,
      tecnicoSelected: null,
      compararProyectos: [],
      compararActividades: [],
      comprobacion: false,
      menu: false,
      desde: null,
      hasta: null,
    };
  },
  computed: {
    ...mapState({
      _estados: "tipoEstados",
      _tipoProyectos: "tipoProyectos",
      _tecnicos: "tecnicos",
      _datosAdministrativos: "datosAdministrativos",
    }),
    _tecnicosComputed() {
      return this._tecnicos.filter((res, index) => {
        if (index > 0) return res;
      });
    },
    _estadosComputed() {
      return this._estados.filter((res, index) => {
        if (index > 0) return res;
      });
    },
  },
  methods: {
    _get() {
      if (!this.tecnicoSelected || !this.desde || !this.hasta) {
        let snackbar = {
          status: true,
          message: "Tienes que llenar todos los campos.",
          type: "error",
        };
        this.$store.commit("_SNACKBAR", snackbar);
        return true;
      }

      this.comprobacion = false;
      this.loadingButton = true;
      this.compararActividades = null;
      this.compararProyectos = null;
      let params = {
        params: {
          tecnicos: [this.tecnicoSelected],
          desde: this.desde,
          hasta: this.hasta,
        },
      };

      axios.get("comparar-tecnicos", params).then((res) => {
        if (res.data.status_code == 200) {
          this.compararActividades = res.data.data[0];
          this.compararProyectos = res.data.data[1];
        }
        this.loadingButton = false;
        this.comprobacion = true;
      });
    },
    _countEstados(estado_id) {
      let count = 0;
      this.compararProyectos.forEach((res) => {
        if (res.proyecto.estado_id == estado_id) {
          count++;
        }
      });
      return count;
    },
    _countTipo(tipo) {
      let count = 0;
      let horas_asignadas = 0;
      let horas_reales = moment.duration("00:00");
      this.compararProyectos.forEach((res) => {
        if (res.proyecto.tipo_proyecto_id == tipo.id) {
          count++;
          horas_asignadas =
            horas_asignadas +
            parseInt((res.proyecto.horas_asignadas * res.participacion) / 100);
          res.proyecto.actividades.forEach((act) => {
            if (act.usuario_id != this.tecnicoSelected) return;
            var startTime = moment(act.hora_inicio, "HH:mm");
            var endTime = moment(act.hora_final, "HH:mm");
            // calculate total duration
            var duration = moment.duration(endTime.diff(startTime));
            // duration in hours
            var hours = parseInt(duration.asHours());
            // duration in minutes
            var minutes = parseInt(duration.asMinutes()) % 60;
            horas_reales.add(moment.duration(`${hours}:${minutes}`));
          });
        }
      });
      return {
        count,
        horas_asignadas,
        horas_reales: moment.duration(horas_reales).asHours(),
      };
    },
    _getReal() {
      let sum = moment.duration("00:00");
      this.compararActividades.filter((usuario) => {
        if (this.tecnicoSelected == usuario.id) {
          usuario.actividades.forEach((act) => {
            var startTime = moment(act.hora_inicio, "HH:mm");
            var endTime = moment(act.hora_final, "HH:mm");

            let from = moment(this.desde, "YYYY-MM-DD");
            let to = moment(this.hasta, "YYYY-MM-DD");
            let check = moment(act.fecha, "YYYY-MM-DD");
            if (check.isBetween(from, to)) {
              // calculate total duration
              var duration = moment.duration(endTime.diff(startTime));
              // duration in hours
              var hours = parseInt(duration.asHours());
              // duration in minutes
              var minutes = parseInt(duration.asMinutes()) % 60;
              sum.add(moment.duration(`${hours}:${minutes}`));
            }
          });
        }
      });
      return moment.duration(sum).asHours();
    },
    _getPlan() {
      //let sum = moment.duration("00:00");
      let sum = 0;
      this.compararProyectos.filter((proyecto) => {
        if (this.tecnicoSelected == proyecto.usuario_id) {
          // loque nos hace falta para calcular todo
          let inicio = proyecto.proyecto.fecha_inicio;
          let final = proyecto.proyecto.fecha_final;
          let horas_asignadas = proyecto.proyecto.horas_asignadas;
          let participacion = proyecto.participacion;

          // hacer la formula
          let diff = moment(final, "YYYY-MM")
            .add(1, "M")
            .diff(moment(inicio, "YYYY-MM"), "months", true);

          sum = sum + (participacion * (horas_asignadas / diff)) / 100;
        }
      });
      return sum.toFixed(2);
    },
    _rentabilidadTecnica() {
      let real = 0;
      let plan = 0;

      real = parseFloat(this._getReal());
      plan = this._getPlan();

      if (real == 0 || plan == 0) {
        return 0;
      }

      return ((real / plan) * 100).toFixed(2);
    },

    _getRentabilidadReal() {
      //Rentabilidad Real: (Horas /Reales / Horas de mes)*100
      let datos = this._datosAdministrativos;
      let response = 0;
      let months;
      let diff;
      let monthsArray = [];
      // asignamos valores de fechas.
      let fecha_inicio = new Date(this.desde);
      let fecha_final = new Date(this.hasta);

      months = (fecha_final.getFullYear() - fecha_inicio.getFullYear()) * 12;
      months -= fecha_inicio.getMonth();
      months += fecha_final.getMonth();
      diff = months <= 0 ? 0 : months;

      for (let i = 0; i <= diff; i++) {
        // mostramos al menos un mes.
        if (i == 0) {
          monthsArray.push(
            new Date(fecha_inicio.setMonth(fecha_inicio.getMonth()))
          );
        } else {
          monthsArray.push(
            new Date(fecha_inicio.setMonth(fecha_inicio.getMonth() + 1))
          );
        }
      }

      monthsArray.forEach((monthRes) => {
        let sum = moment.duration("00:00");
        this.compararActividades.filter((usuario) => {
          if (this.tecnicoSelected == usuario.id) {
            usuario.actividades.forEach((act) => {
              if (moment(act.fecha).isSame(monthRes, "month")) {
                var startTime = moment(act.hora_inicio, "HH:mm");
                var endTime = moment(act.hora_final, "HH:mm");
                // calculate total duration
                var duration = moment.duration(endTime.diff(startTime));
                // duration in hours
                var hours = parseInt(duration.asHours());
                // duration in minutes
                var minutes = parseInt(duration.asMinutes()) % 60;
                sum.add(moment.duration(`${hours}:${minutes}`));
              }
            });
          }
        });

        sum = moment.duration(sum).asHours();
        //una vez tenemos la suma del mes obtener horas DATOS del mes
        let horasMes;
        datos.forEach((res, index) => {
          if (index == 1) {
            res.años.forEach((año) => {
              let key = Object.keys(año).filter((k) => {
                if (
                  moment(monthRes).format("YYYY") == año.año &&
                  k ==
                    moment(monthRes)
                      .locale("es")
                      .format("MMMM")
                ) {
                  return k;
                }
              });

              horasMes = año[key[0]];
              response += (parseFloat(sum) / parseFloat(horasMes)) * 100;
            });
          }
        });
      });
      if (!response) return 0;
      return response.toFixed(2);
    },
    _formatFloat(val) {
      if (!val) return "0,00";

      if (isNaN(val)) return "-";
      return (val * 1)
        .toFixed(2) // always two decimal digits
        .replace(".", ",") // replace decimal point character with ,
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    },
  },
};
</script>

<style lang="scss" scoped>
.crono-col-container {
  display: flex;
  align-items: stretch;
  justify-items: flex-start;
  flex-wrap: nowrap; //wrap
  justify-content: flex-start;
  overflow-x: scroll;
  width: 100%;
}

.control-container {
  display: flex;
  &-div {
    width: 100%;
  }
}

.v-card {
  height: 100%;
}
</style>
