var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"text-center",attrs:{"no-gutters":""}},[(_vm.proyecto)?[(!_vm.proyecto.tareas.length)?_c('v-alert',{attrs:{"type":"error","outlined":"","dense":""}},[_vm._v(" Debes planificar cada tarea del proyecto para visualizar el cronograma. ")]):_vm._e(),_vm._l((_vm.proyecto.tareas),function(tarea,i){return _c('v-col',{key:i,attrs:{"cols":"12"}},[_c('div',{staticClass:"text-caption primary-text mt-5 mb-2 text-left text-uppercase"},[_c('v-icon',[_vm._v("mdi-menu-down")]),_vm._v(" "+_vm._s(tarea.tipo_tarea.nombre)+" ")],1),_c('div',{staticClass:"crono-col-container"},[_vm._l((_vm._monthsDifference()),function(month,i){return _c('div',{key:i,staticClass:"crono-col",style:(_vm.monthsDifference < 24
              ? `flex: 0 0 ${100 / (_vm.monthsDifference + 1)}%;`
              : `flex: 0 0 110px;`),on:{"click":function($event){return _vm._showDialog(month, tarea)}}},[_c('v-card',{staticClass:"pa-0",attrs:{"outlined":"","tile":""}},[_c('div',{staticClass:"col py-3 text-left"},[_c('h5',{staticClass:"text-truncate text-uppercase mb-0",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.monthNames[month.getMonth()])+" "),_c('v-chip',{staticClass:"ml-1 text-uppercase",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.moment(month).year())+" ")])],1)]),_c('v-chip',{attrs:{"color":_vm._monthIsSelected(month, tarea, _vm.proyecto.planificacion)
                  ? 'primary'
                  : 'grey lighten-3',"label":"","text-color":_vm._monthIsSelected(month, tarea, _vm.proyecto.planificacion)
                  ? 'white'
                  : 'black'}},[_c('span',[_vm._v("Prev: "),_c('strong',[_vm._v(_vm._s(_vm._formatFloatHours( _vm._monthIsSelected(month, tarea, _vm.proyecto.planificacion) ? _vm._divisionHoras( tarea, tarea.fecha_inicio, tarea.fecha_final, _vm.proyecto.planificacion ) : "0" )))])])]),_c('v-chip',{attrs:{"color":_vm._getActividadesHoras(month, tarea)
                  ? 'green lighten-1'
                  : 'grey lighten-3',"label":"","text-color":_vm._getActividadesHoras(month, tarea) ? 'white' : 'black'}},[_vm._v(" Real: "),_c('strong',[_vm._v(" "+_vm._s(_vm._formatFloatHours(_vm._getActividadesHoras(month, tarea))))])])],1)],1)}),_c('div',{staticClass:"crono-col",style:(_vm.monthsDifference < 24
              ? `flex: 0 0 ${100 / (_vm.monthsDifference + 1)}%;`
              : `flex: 0 0 110px;`)},[_c('v-card',{staticClass:"pa-0",attrs:{"outlined":"","tile":""}},[_c('div',{staticClass:"col py-3 text-left white--text black"},[_c('h5',{staticClass:"text-truncate text-uppercase mb-0",staticStyle:{"font-size":"12px"}},[_vm._v(" RESUMEN ")])]),_c('v-chip',{attrs:{"label":"","color":"white"}},[_vm._v(" Total horas: "),_c('strong',[_vm._v(_vm._s(_vm._formatFloat(_vm._getActividadesTotales(tarea))))])]),_c('v-chip',{attrs:{"label":"","color":"white"}},[_vm._v(" Cumplimento: "),_c('strong',[_vm._v(" "+_vm._s(_vm._getActividadesCumplimiento( tarea, _vm._getActividadesTotales(tarea), _vm.proyecto.planificacion )))])])],1)],1)],2)])}),_c('v-dialog',{attrs:{"max-width":"450"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-3"},[_c('v-card-title',{staticClass:"pa-2",staticStyle:{"font-size":"17px"}},[_vm._v(" Actividades de "+_vm._s(_vm.currentMes)+" "),_c('v-chip',{staticClass:"ml-3 text-uppercase",attrs:{"x-small":""}},[_vm._v(_vm._s(_vm.tareaMes)+" ")])],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.actividadesMes,"loading-text":"Cargando...","hide-default-footer":""}})],1)],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }