<template>
  <div class="proyecto">
    <proyectos-planificar-form
      :proyectoProp="proyecto"
      :idProp="id"
    ></proyectos-planificar-form>
  </div>
</template>

<script>
import axios from "axios";
import ProyectosPlanificarForm from "@/components/ProyectosPlanificarForm";
export default {
  components: {
    ProyectosPlanificarForm,
  },
  data() {
    return {
      id: null,
      proyecto: null,
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this._getProyecto();
  },
  methods: {
    _getProyecto() {
      axios.get(`proyectos/${this.id}`).then((res) => {
        if (res.data.status_code == 200) {
          this.proyecto = res.data.data;
        }
      });
    },
  },
};
</script>
