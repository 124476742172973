<template>
  <div class="proyecto">
    <div class="text-center loader" v-if="loading">
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </div>

    <v-row v-if="!loading">
      <template v-if="(proyecto && _getUsuario == true) || _auth.a">
        <v-col lg="12">
          <v-card class="pa-4 py-2 elevation-0" tile>
            <v-card-title class="pa-0 mb-8">
              <h2>Proyecto</h2>

              <v-chip class="mx-3 text-uppercase" label
                >{{ proyecto.tipo_proyecto.nombre }}
              </v-chip>

              <v-chip
                label
                :color="_getChipEstado(proyecto.estado.nombre)"
                class="text-uppercase"
                @click="dialogChangeEstado = !dialogChangeEstado"
              >
                {{ proyecto.estado.nombre }}

                <v-icon class="ml-2" small>
                  mdi-pencil
                </v-icon>
              </v-chip>

              <v-dialog v-model="dialogChangeEstado" max-width="500">
                <v-card class="pa-4">
                  <v-card-text class="px-0">
                    <h3 class="mb-3">Cambiar de estado el proyecto</h3>
                    <v-select
                      v-if="_estados"
                      v-model="estado_id"
                      :items="_estadosComputed"
                      label="Estado"
                      hide-details="true"
                      dense
                      outlined
                      item-text="nombre"
                      item-value="id"
                    ></v-select>

                    <div class="grey lighten-3 my-3">
                      <fuera-plazo
                        v-if="estado_id == 2"
                        fromDetalle="true"
                        :proyectoId="proyecto.id"
                      ></fuera-plazo>
                    </div>
                  </v-card-text>

                  <v-alert text type="error" v-if="estado_id == 2" dense>
                    Una vez asignes fecha y causa debes guardar el proyecto.
                  </v-alert>

                  <v-btn
                    color="primary"
                    class="px-4"
                    large
                    depressed
                    @click="_changeEstado()"
                    :loading="loadingButton"
                    :disabled="loadingButton"
                  >
                    <v-icon class="mr-2">
                      mdi-cloud-upload
                    </v-icon>
                    Guardar proyecto
                  </v-btn>
                </v-card>
              </v-dialog>
            </v-card-title>

            <v-card class="elevation-1 mb-8" v-if="fueraPlazo">
              <v-card-text>
                <div>
                  <v-chip
                    class="text-uppercase mb-3"
                    color="error"
                    outlined
                    label
                    small
                    >MOTIVO RETRASO
                  </v-chip>
                </div>
                <div class="text--primary mb-1">
                  Fecha final prevista:
                  <strong>{{ fueraPlazo.fecha_final_prevista }}</strong>
                </div>
                <v-divider class="my-2"></v-divider>
                <div class="text--primary mb-1">
                  Fecha actualización: <strong>{{ fueraPlazo.fecha }}</strong>
                </div>

                <div class="text--primary">Causa: {{ fueraPlazo.causa }}</div>
              </v-card-text>
            </v-card>

            <v-divider class="my-3"></v-divider>
            Cliente:
            <strong v-if="proyecto.cliente">{{
              proyecto.cliente.nombre_comercial
            }}</strong>
            <v-divider class="my-3"></v-divider>
            Esquema:
            <strong v-if="proyecto.tipo_esquema">{{
              proyecto.tipo_esquema.nombre
            }}</strong>
            <v-divider class="my-3"></v-divider>
            Descripción:
            <strong v-if="proyecto.descripcion">{{
              proyecto.descripcion
            }}</strong>
            <v-divider class="my-3"></v-divider>
            Fecha de contacto con cliente:
            <strong v-if="proyecto.fecha_contacto">{{
              proyecto.fecha_contacto
            }}</strong>
            <v-divider class="my-3"></v-divider>
            Observaciones:
            <strong v-if="proyecto.observaciones">{{
              proyecto.observaciones
            }}</strong>
          </v-card>
        </v-col>

        <v-col lg="6" cols="sm" class="pb-2">
          <v-card tile class="elevation-0">
            <v-row class="no-gutters">
              <div class="col-auto">
                <div class="primary fill-height" style="width: 5px;"></div>
              </div>
              <div class="col pa-3 py-4 primary--text">
                <h5 class="text-truncate text-uppercase mb-3">
                  Técnicos asignados
                </h5>

                <v-alert
                  v-if="!proyecto.tecnicos.length"
                  type="error"
                  outlined
                  dense
                >
                  Debes asignar los técnicos del proyecto.
                </v-alert>

                <v-alert
                  v-if="errorParticipacion && proyecto.tecnicos.length"
                  type="error"
                  outlined
                  dense
                >
                  La suma de la participación debe ser del 100%. Puede que hayas
                  borrado un técnico por error. Prueba con asignar un nuevo
                  técnico.
                </v-alert>

                <v-list>
                  <v-list-item
                    two-line
                    v-for="(tecnico, i) in proyecto.tecnicos"
                    :key="i"
                    class="pa-0"
                  >
                    <v-list-item-content class="custom-list">
                      <h3 class="mb-3">{{ tecnico.usuario.nombre }}</h3>

                      <p>
                        Participación:
                        <strong
                          >{{
                            _formatFloat(
                              _getResumenTecnico(tecnico).participacion
                            )
                          }}
                          %</strong
                        >
                      </p>

                      <p>
                        Horas asignadas:
                        <strong
                          >{{
                            _formatFloat(_getResumenTecnico(tecnico).asignadas)
                          }}
                          h</strong
                        >
                      </p>
                      <p>
                        Reales:
                        <strong
                          >{{
                            _formatFloat(_getResumenTecnico(tecnico).reales)
                          }}
                          h ({{
                            _formatFloat(_getResumenTecnico(tecnico).porcentaje)
                          }}
                          %)</strong
                        >
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </v-row>
          </v-card>
        </v-col>

        <v-col lg="6" cols="sm" class="pb-2">
          <v-card tile class="elevation-0">
            <v-row class="no-gutters">
              <div class="col-auto">
                <div class="primary fill-height" style="width: 5px;"></div>
              </div>
              <div class="col pa-3 py-4 primary--text">
                <h5 class="text-truncate text-uppercase mb-3">
                  Evolución Tareas
                </h5>

                <v-list>
                  <v-list-item
                    two-line
                    v-for="(tarea, i) in proyecto.tareas"
                    :key="i"
                    class="pa-0"
                    @click="_changeEvolucion(tarea)"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="tarea.tipo_tarea.nombre"
                      ></v-list-item-title>
                      <h4>{{ tarea.evolucion }}%</h4>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <h4>GLOBAL</h4>
                <h2 class="black--text" @click="_changeEvolucionGlobal()">
                  {{ proyecto.evolucion }}%
                </h2>
              </div>
            </v-row>
          </v-card>
        </v-col>

        <v-dialog v-model="dialogEvolucion" max-width="650">
          <tarea-evolucion
            :tarea="tareaEvolucion"
            @saveEvolucion="_getProyectoEvolucion"
          ></tarea-evolucion>
        </v-dialog>

        <v-dialog v-model="dialogEvolucionGlobal" max-width="650">
          <global-evolucion
            :proyecto="proyecto"
            @saveEvolucion="_getProyectoEvolucion"
          ></global-evolucion>
        </v-dialog>

        <v-col lg="12">
          <v-card tile class="pa-3 elevation-0">
            <cronograma :proyectoProp="proyecto"></cronograma>
          </v-card>
        </v-col>

        <v-col lg="12">
          <v-tabs v-model="tab" background-color="transparent" grow>
            <v-tab v-for="item in tabsItems" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <actividades-proyecto
                v-if="proyecto"
                :proyecto="proyecto"
                @delete="_onDelete(id)"
              ></actividades-proyecto>

              <v-btn
                color="primary"
                class="my-6"
                dark
                depressed
                @click="_nuevaActividad()"
              >
                <v-icon class="mr-2">
                  mdi-plus
                </v-icon>
                Nueva actividad
              </v-btn>
            </v-tab-item>

            <v-tab-item>
              <gastos-proyecto
                v-if="proyecto"
                :proyecto="proyecto"
                @delete="_onDelete(id)"
              ></gastos-proyecto>

              <v-btn
                color="primary"
                class="my-6"
                dark
                depressed
                @click="_nuevoGasto()"
              >
                <v-icon class="mr-2">
                  mdi-plus
                </v-icon>
                Nuevo Gasto
              </v-btn>
            </v-tab-item>

            <v-tab-item>
              <notas-proyecto
                v-if="proyecto"
                :proyecto="proyecto"
              ></notas-proyecto>
              <v-btn
                color="primary"
                class="my-6"
                dark
                depressed
                @click="_nuevaNota()"
              >
                <v-icon class="mr-2">
                  mdi-plus
                </v-icon>
                Nueva Nota
              </v-btn>
            </v-tab-item>
          </v-tabs-items>
        </v-col>

        <v-col lg="12" cols="sm" class="pb-2" v-if="proyecto.notas.length">
          <v-card tile class="elevation-0">
            <v-row class="no-gutters">
              <div class="col-auto">
                <div class="primary fill-height" style="width: 5px;"></div>
              </div>
              <div class="col pa-3 py-4 primary--text">
                <h5 class="text-truncate text-uppercase mb-3">
                  Notas
                </h5>

                <notas v-if="proyecto" :notas="proyecto.notas"></notas>
              </div>
            </v-row>
          </v-card>
        </v-col>

        <v-col lg="12" v-if="_auth.a">
          <resumen-proyecto-admin :proyecto="proyecto"></resumen-proyecto-admin>
        </v-col>
      </template>
      <v-alert v-else text type="error">
        No tienes permisos para ver está sección
      </v-alert>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import Cronograma from "@/components/Cronograma.vue";
import ActividadesProyecto from "@/components/ActividadesProyecto";
import GastosProyecto from "@/components/GastosProyecto";
import NotasProyecto from "@/components/NotasProyecto";
import TareaEvolucion from "@/components/TareaEvolucion";
import GlobalEvolucion from "@/components/GlobalEvolucion";
import Notas from "@/components/Notas";
import FueraPlazo from "@/components/FueraPlazo";
import axios from "axios";
import { mapState } from "vuex";
import ResumenProyectoAdmin from "../components/ResumenProyectoAdmin.vue";
export default {
  components: {
    Cronograma,
    ActividadesProyecto,
    GastosProyecto,
    NotasProyecto,
    Notas,
    TareaEvolucion,
    GlobalEvolucion,
    FueraPlazo,
    ResumenProyectoAdmin,
  },
  data: () => ({
    loading: true,
    loadingButton: false,
    id: null,
    estado_id: null,
    proyecto: null,
    fueraPlazo: null,
    editarActividad: false,
    editarGasto: false,
    editarNota: false,
    error: false,
    dialogEvolucion: false,
    dialogEvolucionGlobal: false,
    dialogChangeEstado: false,
    tareaEvolucion: null,
    tab: null,
    tabsItems: ["Actividades", "Gastos", "Notas"],
    errorParticipacion: false,
  }),
  mounted() {
    this.id = this.$route.params.id;
    axios.get(`/proyectos/${this.id}`).then((res) => {
      if (res.data.status_code == 200) {
        this.proyecto = res.data.data;
        if (!this.proyecto.evolucion) this.proyecto.evolucion = 0;

        let sum = 0;
        this.proyecto.tecnicos.forEach((tec) => {
          sum = sum + parseInt(tec.participacion);
        });

        if (sum != 100) this.errorParticipacion = true;
      }
      this.loading = false;
    });
  },
  computed: {
    ...mapState({
      _auth: "auth",
      _estados: "tipoEstados",
    }),
    _estadosComputed() {
      return this._estados.filter((res) => {
        return res;
      });
    },
    _getUsuario() {
      let userCanEdit = false;
      this.proyecto.tecnicos.forEach((tecnico) => {
        if (tecnico.usuario_id == this._auth.id) {
          userCanEdit = true;
        }
      });

      return userCanEdit;
    },
    _gastosTotales() {
      let sum = 0;
      this.proyecto.tareas.forEach((el) => {
        el.actividades.forEach((act) => {
          if (act.gastos) sum = sum + parseInt(act.gastos);
        });
      });
      return sum;
    },
  },
  watch: {
    proyecto(val) {
      if (!val.evolucion) this.proyecto.evolucion = 0;

      if (val.estado.id == 2) {
        this._fueraPlazo();
      } else {
        this.fueraPlazo = null;
      }
    },
  },
  methods: {
    _nuevaActividad() {
      this.editarActividad = false;
      this.$router.push(`/editar/actividad/${this.proyecto.id}/nueva`);
    },
    _nuevoGasto() {
      this.editarGasto = false;
      this.$router.push(`/editar/gasto/${this.proyecto.id}/nueva`);
    },
    _nuevaNota() {
      this.editarNota = false;
      this.$router.push(`/editar/nota/${this.proyecto.id}/nueva`);
    },
    _fueraPlazo() {
      axios.get(`fuera-plazo/${this.proyecto.id}`).then((res) => {
        this.fueraPlazo = res.data.data;
      });
    },
    _getResumenTecnico(tecnico) {
      let participacion = tecnico.participacion;
      let horasAsignadas = this.proyecto.horas_asignadas;
      let sum = moment.duration("00:00");
      let asignadasTotales = 0;
      let realesTotales = 0;
      this.proyecto.tareas.forEach((tarea) => {
        tarea.actividades.forEach((act) => {
          if (act.usuario.id == tecnico.usuario_id) {
            var startTime = moment(act.hora_inicio, "HH:mm");
            var endTime = moment(act.hora_final, "HH:mm");
            // calculate total duration
            var duration = moment.duration(endTime.diff(startTime));
            // duration in hours
            var hours = parseInt(duration.asHours());
            // duration in minutes
            var minutes = parseInt(duration.asMinutes()) % 60;
            sum.add(moment.duration(`${hours}:${minutes}`));
          }
        });
      });

      asignadasTotales = parseFloat((horasAsignadas * participacion) / 100);
      realesTotales = moment.duration(sum).asHours();

      let porcentaje = 0;

      if (realesTotales) {
        porcentaje = (
          (100 * parseFloat(realesTotales)) /
          parseFloat(asignadasTotales)
        ).toFixed(2);
      }
      return {
        participacion: participacion,
        asignadas: (participacion * this.proyecto.horas_asignadas) / 100,
        reales: realesTotales,
        porcentaje,
      };
    },
    _getChipEstado(estado) {
      let color = "success";
      switch (estado) {
        case "Abierto":
          color = "success";
          break;
        case "Cerrado":
          color = "error";
          break;
        case "Abierto Fuera de Plazo":
          color = "error";
          break;
        case "Inactivo":
          color = "grey";
          break;
      }
      return color;
    },
    _changeEvolucion(tarea) {
      this.tareaEvolucion = tarea;
      this.dialogEvolucion = true;
    },
    _changeEvolucionGlobal() {
      this.dialogEvolucionGlobal = true;
    },
    _changeEstado() {
      this.loadingButton = true;
      let params = {
        id: this.id,
        estado_id: this.estado_id,
      };
      axios.post("cambiar-estado", params).then((res) => {
        if (res.data.status_code != 200) {
          let snackbar = {
            status: true,
            message: res.data.data,
            type: "error",
          };
          this.$store.commit("_SNACKBAR", snackbar);
        } else {
          this.proyecto = res.data.data;

          let snackbar = {
            status: true,
            message: "El estado del proyecto ha sido cambiado.",
            type: "success",
          };
          this.$store.commit("_SNACKBAR", snackbar);
        }

        this.loadingButton = false;
        this.dialogChangeEstado = false;
      });
    },
    _getProyectoEvolucion(val) {
      this.proyecto = val;
      this.dialogEvolucion = false;
      this.dialogEvolucionGlobal = false;
    },
    _sumEvolucion() {
      let suma = 0;
      let tareas = 0;
      this.proyecto.tareas.forEach((res) => {
        tareas = tareas + parseInt(1);
        suma = suma + parseFloat(res.evolucion);
      });
      return suma / tareas;
    },
    _onDelete(id) {
      axios.get(`/proyectos/${id}`).then((res) => {
        if (res.data.status_code == 200) {
          this.proyecto = res.data.data;
        }
        this.loading = false;
      });
    },
    _formatFloat(val) {
      if (!val) return "0,00";

      if (isNaN(val)) return "-";
      return (val * 1)
        .toFixed(2) // always two decimal digits
        .replace(".", ",") // replace decimal point character with ,
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    },
  },
};
</script>

<style lang="scss">
.custom-list {
  p {
    margin: 6px 0 !important;
  }
}
</style>
