<template>
  <div>
    <div class="text-center loader" v-if="loading">
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </div>

    <v-card v-else class="elevation-0">
      <template v-if="_getUsuario == true">
        <v-card-title>
          <h2 v-if="id">Planificar Proyecto</h2>

          <v-chip class="ma-2" label> Código: {{ codigo }} </v-chip>
        </v-card-title>
        <v-card-text>
          <!-- Asignar tareas -->
          <v-row>
            <v-col cols="12">
              <h3 class="mb-3">Planificar tareas</h3>

              <v-card class="pa-6 mb-3" outlined>
                <v-alert dense text color="info" icon="mdi-alert">
                  <div>
                    Las fechas de cada tarea deben estar entre:
                    <strong>{{ fecha_inicio }}</strong> y
                    <strong>{{ fecha_final }}</strong
                    >.
                  </div>

                  <div>
                    La suma de horas de las tareas debe ser igual a
                    <strong>{{ horas_asignadas }}</strong
                    >.
                  </div>
                </v-alert>

                <div
                  v-for="(item, index) in tareas"
                  :key="index"
                  :id="`tarea_${index}`"
                >
                  <v-row class="mt-8">
                    <v-col cols="3" class="pb-0">
                      <v-select
                        v-if="_tipoTareas"
                        v-model="tareas[index].tipo_tarea_id"
                        name="tareas[][tipo_tarea_id]"
                        :items="_tipoTareas"
                        dense
                        label="Tarea"
                        outlined
                        disabled
                        item-text="nombre"
                        item-value="id"
                      ></v-select>
                    </v-col>

                    <v-col cols="3" class="pb-0">
                      <v-menu
                        ref="fecha_inicio_tarea"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="tareas[index].fecha_inicio"
                            label="Fecha de inicio de tarea"
                            persistent-hint
                            outlined
                            dense
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="tareas[index].fecha_inicio"
                          no-title
                          color="primary"
                          @input="fecha_inicio_tarea = false"
                          locale="es"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col cols="3" class="pb-0">
                      <v-menu
                        ref="fecha_final_tarea"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="tareas[index].fecha_final"
                            label="Fecha final de tarea"
                            persistent-hint
                            outlined
                            dense
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="tareas[index].fecha_final"
                          no-title
                          color="primary"
                          @input="fecha_final_tarea = false"
                          locale="es"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col cols="3" class="pb-0">
                      <v-text-field
                        outlined
                        dense
                        v-model="tareas[index].horas"
                        label="Duración en horas"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-4 pb-6">
          <v-btn
            color="primary"
            class="px-4"
            large
            depressed
            @click="_planificarProyecto()"
            :loading="loadingButton"
            :disabled="loadingButton"
          >
            <v-icon class="mr-2">
              mdi-cloud-upload
            </v-icon>
            Guardar planificación
          </v-btn>
        </v-card-actions>
      </template>

      <v-alert v-else text type="error">
        No tienes permisos para planificar este proyecto.
      </v-alert>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapState } from "vuex";
export default {
  props: ["proyectoProp", "idProp"],
  data() {
    return {
      loading: true,
      loadingButton: false,
      menu: false,
      tareas: [],
      tarea: {
        id: null,
        proyecto_id: null,
        tipo_tarea_id: null,
        fecha_inicio: null,
        fecha_final: null,
        horas: null,
      },
      proyecto: null,
      id: null,
    };
  },
  computed: {
    ...mapState({
      _auth: "auth",
      _tipoTareas: "tipoTareas",
      _estados: "tipoEstados",
    }),
    _getUsuario() {
      let userCanEdit = false;
      this.tecnicos.forEach((tecnico) => {
        if (tecnico.usuario_id == this._auth.id) {
          userCanEdit = true;
        }
      });

      return userCanEdit;
    },
  },
  watch: {
    proyectoProp(val) {
      if (val) this._loadProyecto(val);
      this.loading = false;
    },
    _tipoTareas(val) {
      if (val && !this.idProp) this.loading = false;
    },
  },
  methods: {
    _loadProyecto(val) {
      if (val) {
        this.id = val.id;
        this.tareas = val.tareas;
        this.tecnicos = val.tecnicos;
        this.codigo = val.codigo;
        this.tipo_proyecto_id = val.tipo_proyecto_id;
        this.tipo_esquema_id = val.tipo_esquema_id;
        this.cliente_id = val.cliente_id;
        this.estado_id = val.estado_id;
        this.fecha_contacto = val.fecha_contacto;
        this.descripcion = val.descripcion;
        this.observaciones = val.observaciones;
        this.entidad_certificadora = val.entidad_certificadora;
        this.accion_formativa = val.accion_formativa;
        this.fecha_inicio = val.fecha_inicio;
        this.fecha_final = val.fecha_final;
        this.horas_asignadas = val.horas_asignadas;
        this.importe_venta = val.importe_venta;
        this.planificacion = val.planificacion;
      }
    },
    _planificarProyecto() {
      this.loadingButton = true;
      let params = {
        proyecto_id: this.id,
        tareas: this.tareas,
      };

      let errorDate = false;
      let errorDateRange = false;
      let sumaHoras = 0;
      this.tareas.forEach((tarea) => {
        sumaHoras = sumaHoras + parseInt(tarea.horas);
        if (!tarea.fecha_inicio || !tarea.fecha_final) {
          errorDate = true;
          return true;
        }

        let compararInicio = moment(tarea.fecha_inicio, "YYYY-MM");
        let compararFinal = moment(tarea.fecha_final, "YYYY-MM");
        let inicio = moment(this.fecha_inicio, "YYYY-MM").subtract(1, "M");
        let final = moment(this.fecha_final, "YYYY-MM").add(1, "M");

        if (
          !compararInicio.isBetween(inicio, final) ||
          !compararFinal.isBetween(inicio, final)
        ) {
          errorDateRange = true;
          return true;
        }
      });

      if (sumaHoras != this.horas_asignadas) {
        let snackbar = {
          status: true,
          message: "Las horas asignadas a las tareas no coinciden.",
          type: "error",
        };
        this.$store.commit("_SNACKBAR", snackbar);
        this.loadingButton = false;
        return true;
      }
      if (errorDate) {
        let snackbar = {
          status: true,
          message: "Debes que asignar fecha para cada tarea.",
          type: "error",
        };
        this.$store.commit("_SNACKBAR", snackbar);
        this.loadingButton = false;
        return true;
      }
      if (errorDateRange) {
        let snackbar = {
          status: true,
          message:
            "Las fechas de tareas deben estar entre las fecha establecidas en el proyecto.",
          type: "error",
        };
        this.$store.commit("_SNACKBAR", snackbar);
        this.loadingButton = false;
        return true;
      }

      axios.post("tareas", params).then((res) => {
        if (res.data.status_code != 200) {
          let snackbar = {
            status: true,
            message: res.data.data,
            type: "error",
          };
          this.$store.commit("_SNACKBAR", snackbar);
        } else {
          this._loadProyecto(res.data.data);

          let snackbar = {
            status: true,
            message: "La planificación se ha guardado correctamente.",
            type: "success",
          };
          this.$store.commit("_SNACKBAR", snackbar);
        }
        this.loadingButton = false;
      });
    },
  },
};
</script>
