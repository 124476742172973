<template>
  <div class="configuracion">
    <h1>Ajustes generales</h1>

    <!-- accion para borrar -->
    <v-dialog v-model="borrarDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="text-h5">
          Borrar dato administrativo
        </v-card-title>
        <v-card-text
          >¿Estás seguro de eliminar este dato? Una vez borrado no se podrá
          recuperar.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="borrarDialog = false">
            No
          </v-btn>
          <v-btn color="primary" text @click="_deleteComplete()">
            Sí
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-toolbar class="mb-2" color="white" flat>
      <v-select
        v-model="tipo"
        :items="tiposDefault"
        label="Tipos"
        @change="_loadTipos()"
        clearable
        flat
        solo
        hide-details
        dense
        item-text="nombre"
        item-value="slug"
      ></v-select>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-card class="elevation-0">
      <v-data-table
        v-if="tipos"
        :headers="headers"
        :items="tipos"
        class="elevation-0"
        :loading="loading"
        loading-text="Cargando..."
        hide-default-footer
        :items-per-page="100"
      >
        <template v-slot:item.acciones="{ item }">
          <v-btn
            color="primary"
            depressed
            class="mr-2"
            small
            dark
            :to="`/editar/datos/${tipo}/${item.id}`"
          >
            <v-icon small>
              mdi-pencil
            </v-icon>
          </v-btn>

          <v-btn
            color="error"
            depressed
            class="mr-2"
            small
            dark
            @click="_delete(tipo, item.id)"
          >
            <v-icon small>
              mdi-delete
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:no-data>
          <v-alert text type="info" class="text-left my-2" tile>
            No hay datos que mostrar.
          </v-alert>
        </template>
      </v-data-table>
    </v-card>

    <v-col lg="12">
      <v-btn
        v-if="tipo"
        color="primary"
        class="my-6"
        dark
        depressed
        :to="`/editar/datos/${tipo}`"
      >
        <v-icon class="mr-2">
          mdi-plus
        </v-icon>
        Añadir nuevo {{ tipo.replace("-", " ") }}
      </v-btn>
    </v-col>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
export default {
  name: "Datos",
  data() {
    return {
      loading: true,
      deleteItem: null,
      deleteTipo: null,
      borrarDialog: false,
      tiposDefault: [
        { nombre: "Tipo Proyectos", slug: "tipo-proyectos" },
        { nombre: "Tipo Esquemas", slug: "tipo-esquemas" },
        { nombre: "Tipo Tareas", slug: "tipo-tareas" },
        { nombre: "Tipo Actividades", slug: "tipo-actividades" },
        { nombre: "Estados", slug: "estados" },
        { nombre: "Datos administrativos", slug: "datos-administrativos" },
      ],
      tipos: [],
      tipo: "tipo-proyectos",
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "nombre",
        },
        { text: "Acciones", aligns: "end", value: "acciones" },
      ],
    };
  },
  mounted() {
    this._loadTipos(null);
  },
  methods: {
    _loadTipos(del = null) {
      this.loading = true;

      if (!del) this.tipos = [];

      axios.get(`/${this.tipo}`).then((res) => {
        if (res.data.status_code == 200) {
          this.tipos = res.data.data;
        }
        this.loading = false;
      });
    },
    _delete(tipo, id) {
      this.borrarDialog = true;
      this.deleteItem = id;
      this.deleteTipo = tipo;
    },
    _deleteComplete() {
      this.loading = true;
      axios.delete(`/${this.deleteTipo}/${this.deleteItem}`).then((res) => {
        if (res.data.status_code == 200) {
          let del = res.data.data;

          if (del) {
            let snackbar = {
              status: true,
              message: "Los datos se han borrado correctamente.",
              type: "success",
            };

            if (this.deleteTipo == "tipo-tareas")
              this.$store.dispatch("_tipoTareas");
            if (this.deleteTipo == "tipo-esquemas")
              this.$store.dispatch("_tipoEsquemas");
            if (this.deleteTipo == "tipo-actividades")
              this.$store.dispatch("_tipoActividades");
            if (this.deleteTipo == "tipo-proyectos")
              this.$store.dispatch("_tipoProyectos");
            if (this.deleteTipo == "estados")
              this.$store.dispatch("_tipoEstados");
            if (this.deleteTipo == "datos_administrativos")
              this.$store.dispatch("_datosAdministrativos");

            this.$store.commit("_SNACKBAR", snackbar);

            this.tipo = this.deleteTipo;
            this._loadTipos(del);
          }
        }
        this.loading = false;
        this.borrarDialog = false;
      });
    },
  },
};
</script>
