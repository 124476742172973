<template>
  <div class="tecnicos">
    <usuarios-form v-if="tecnico" :usuarioProp="tecnico"></usuarios-form>
  </div>
</template>

<script>
import axios from "axios";
import UsuariosForm from "@/components/UsuariosForm";
export default {
  components: {
    UsuariosForm,
  },
  data: () => ({
    id: null,
    tecnico: {},
  }),
  mounted() {
    this.id = this.$route.params.id;
    if (this.id) {
      this._getUsuario();
    } else {
      this.tecnico = "nuevo";
    }
  },
  methods: {
    _getUsuario() {
      axios.get(`/tecnicos/${this.id}`).then((res) => {
        if (res.data.status_code == 200) {
          this.tecnico = res.data.data;
        }
      });
    },
  },
};
</script>
