<template>
  <div class="actividades">
    <actividades-form
      :proyectoProp="proyecto"
      :actividad="id_actividad"
    ></actividades-form>
  </div>
</template>

<script>
import axios from "axios";
import ActividadesForm from "@/components/ActividadesForm";
export default {
  components: {
    ActividadesForm,
  },
  data() {
    return {
      id_proyecto: null,
      id_actividad: null,
      proyecto: null,
    };
  },
  mounted() {
    this.id_proyecto = this.$route.params.id_proyecto;
    this.id_actividad = this.$route.params.id_actividad;
    this._getProyecto();
  },
  methods: {
    _getProyecto() {
      axios.get(`proyectos/${this.id_proyecto}`).then((res) => {
        if (res.data.status_code == 200) {
          this.proyecto = res.data.data;
        }
      });
    },
  },
};
</script>
