<template>
  <div class="controles">
    <!-- Repeater técnicos -->
    <v-card class="elevation-0">
      <v-card-title>
        <h2>Proyectos</h2>
        <v-chip class="ml-3 text-uppercase" label
          >Sobre el Conjunto de proyectos</v-chip
        >
      </v-card-title>

      <v-card-text class="pb-0 mt-3">
        <v-row class="mb-0">
          <v-col cols="6">
            <v-menu
              ref="desde"
              v-model="menu"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="desde"
                  label="Desde"
                  prepend-icon="mdi-calendar"
                  readonly
                  hide-details="true"
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="desde"
                color="primary"
                no-title
                scrollable
                locale="es"
                hide-details="true"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-menu
              ref="hasta"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="hasta"
                  label="Hasta"
                  prepend-icon="mdi-calendar"
                  hide-details="true"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="hasta"
                color="primary"
                no-title
                scrollable
                locale="es"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="px-0 pb-6">
        <v-btn
          color="primary"
          class="px-8 ml-12"
          large
          depressed
          @click="_comparar()"
          :loading="loadingButton"
          :disabled="loadingButton"
        >
          <v-icon class="mr-2">
            mdi-eye
          </v-icon>
          Comparar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-row v-if="comprobacion">
      <v-col lg="12" cols="sm" class="mt-4 pb-2">
        <v-card tile class="elevation-0">
          <v-row class="no-gutters">
            <div class="col-auto">
              <div class="primary fill-height" style="width: 5px;"></div>
            </div>
            <div class="col pa-3 py-4 primary--text">
              <h5 class="text-truncate text-uppercase mb-3">
                Proyectos por estado
              </h5>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Estado
                      </th>
                      <th class="text-left">
                        Nº Proyectos
                      </th>
                      <th class="text-left">
                        Importe
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in _estadosComputed" :key="item.id">
                      <td>{{ item.nombre }}</td>
                      <td>{{ _countEstados(item.id) }}</td>
                      <td>{{ _formatFloat(_countImporte(item.id)) }} €</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-row>
        </v-card>
      </v-col>

      <v-col lg="6" cols="sm" class="mt-4 pb-2">
        <v-card tile class="elevation-0">
          <v-row class="no-gutters">
            <div class="col-auto">
              <div class="primary fill-height" style="width: 5px;"></div>
            </div>
            <div class="col pa-3 py-4 primary--text">
              <h5 class="text-truncate text-uppercase mb-3">
                Nº Proyectos por Tipo, horas Asignadas y Horas Reales
              </h5>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Tipo
                      </th>
                      <th class="text-left">
                        Cantidad
                      </th>
                      <th class="text-left">
                        Horas asignadas
                      </th>
                      <th class="text-left">
                        Horas reales
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in _tipoProyectos" :key="item.id">
                      <td>{{ item.nombre }}</td>
                      <td>{{ _countTipo(item).count }}</td>
                      <td>
                        {{ _formatFloat(_countTipo(item).horas_asignadas) }}
                      </td>
                      <td>{{ _formatFloat(_countTipo(item).horas_reales) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-row>
        </v-card>
      </v-col>

      <v-col lg="6" cols="sm" class="mt-4 pb-2">
        <v-card tile class="elevation-0">
          <v-row class="no-gutters">
            <div class="col-auto">
              <div class="primary fill-height" style="width: 5px;"></div>
            </div>
            <div class="col pa-3 py-4 primary--text">
              <h5 class="text-truncate text-uppercase mb-3">
                Nº Proyectos por Esquema
              </h5>

              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Tipo
                      </th>
                      <th class="text-left">
                        Cantidad
                      </th>
                      <th class="text-left">
                        Horas asignadas
                      </th>
                      <th class="text-left">
                        Horas reales
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in _tipoEsquemas" :key="item.id">
                      <td>{{ item.nombre }}</td>
                      <td>{{ _countEsquema(item).count }}</td>
                      <td>
                        {{ _formatFloat(_countEsquema(item).horas_asignadas) }}
                      </td>
                      <td>
                        {{ _formatFloat(_countEsquema(item).horas_reales) }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <!-- Repeater técnicos -->
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapState } from "vuex";
export default {
  name: "Proyectos",
  data() {
    return {
      loading: true,
      loadingButton: false,
      menu: false,
      comprobacion: false,
      desde: null,
      hasta: null,
      proyectos: [],
      estados: null,
      headers: [
        { text: "Tipo", value: "tipo" },
        { text: "Cantidad", value: "cantidad" },
        { text: "Horas asignadas", value: "horas_asignadas" },
        { text: "Horas reales", value: "horas_reales" },
      ],
    };
  },
  computed: {
    ...mapState({
      _estados: "tipoEstados",
      _tipoProyectos: "tipoProyectos",
      _tipoEsquemas: "tipoEsquemas",
      _datosAdministrativos: "datosAdministrativos",
    }),
    _estadosComputed() {
      return this._estados.filter((res, index) => {
        if (index > 0) return res;
      });
    },
  },
  methods: {
    _comparar() {
      if (!this.desde || !this.hasta) {
        let snackbar = {
          status: true,
          message: "Tienes que llenar todos los campos.",
          type: "error",
        };
        this.$store.commit("_SNACKBAR", snackbar);
        return true;
      }

      this.comprobacion = false;
      this.loadingButton = true;
      let params = {
        params: {
          desde: this.desde,
          hasta: this.hasta,
        },
      };
      axios.get("comparar-proyectos", params).then((res) => {
        if (res.data.status_code == 200) {
          this.proyectos = res.data.data;
        }
        this.loadingButton = false;
        this.comprobacion = true;
      });
    },
    _countEstados(estado_id) {
      let count = 0;
      this.proyectos.forEach((res) => {
        if (res.estado_id == estado_id) {
          count++;
        }
      });
      return count;
    },
    _countImporte(estado_id) {
      let count = 0;
      this.proyectos.forEach((res) => {
        if (res.estado_id == estado_id) {
          count = count + parseFloat(res.importe_venta);
        }
      });
      return count;
    },
    _countTipo(tipo) {
      let count = 0;
      let horas_asignadas = 0;
      let horas_reales = moment.duration("00:00");
      this.proyectos.forEach((res) => {
        if (res.tipo_proyecto_id == tipo.id) {
          count++;
          horas_asignadas = horas_asignadas + parseInt(res.horas_asignadas);

          res.actividades.forEach((act) => {
            var startTime = moment(act.hora_inicio, "HH:mm");
            var endTime = moment(act.hora_final, "HH:mm");
            // calculate total duration
            var duration = moment.duration(endTime.diff(startTime));
            // duration in hours
            var hours = parseInt(duration.asHours());
            // duration in minutes
            var minutes = parseInt(duration.asMinutes()) % 60;
            horas_reales.add(moment.duration(`${hours}:${minutes}`));
          });
        }
      });
      return {
        count,
        horas_asignadas,
        horas_reales: moment.duration(horas_reales).asHours(),
      };
    },
    _countEsquema(tipo) {
      let count = 0;
      let horas_asignadas = 0;
      let horas_reales = moment.duration("00:00");
      this.proyectos.forEach((res) => {
        if (res.tipo_esquema_id == tipo.id) {
          count++;
          horas_asignadas = horas_asignadas + parseInt(res.horas_asignadas);

          res.actividades.forEach((act) => {
            var startTime = moment(act.hora_inicio, "HH:mm");
            var endTime = moment(act.hora_final, "HH:mm");
            // calculate total duration
            var duration = moment.duration(endTime.diff(startTime));
            // duration in hours
            var hours = parseInt(duration.asHours());
            // duration in minutes
            var minutes = parseInt(duration.asMinutes()) % 60;
            horas_reales.add(moment.duration(`${hours}:${minutes}`));
          });
        }
      });
      return {
        count,
        horas_asignadas,
        horas_reales: moment.duration(horas_reales).asHours(),
      };
    },
    _formatFloat(val) {
      if (!val) return "0,00";

      if (isNaN(val)) return "-";
      return (val * 1)
        .toFixed(2) // always two decimal digits
        .replace(".", ",") // replace decimal point character with ,
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    },
  },
};
</script>
