<template>
  <div class="gastos">
    <gastos-form :gasto="gasto" :proyectoId="id_proyecto"></gastos-form>
  </div>
</template>

<script>
import axios from "axios";
import GastosForm from "@/components/GastosForm";
export default {
  components: {
    GastosForm,
  },
  data() {
    return {
      id_gasto: null,
      gasto: null,
      id_proyecto: null,
    };
  },
  mounted() {
    this.id_proyecto = this.$route.params.id_proyecto;
    this.id_gasto = this.$route.params.id_gasto;
    if (this.id_gasto != "nueva") {
      this._getGasto();
    } else {
      this.gasto = false;
    }
  },
  methods: {
    _getGasto() {
      axios.get(`gasto/${this.id_gasto}`).then((res) => {
        if (res.data.status_code == 200) {
          this.gasto = res.data.data;
        }
      });
    },
  },
};
</script>
