<template>
  <v-row>
    <v-col cols="12">
      <v-card class="elevation-0">
        <v-card-title>
          <h2>Notas</h2>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="notas"
          :loading="loading"
          loading-text="Cargando notas..."
          class="elevation-0"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:item.tecnico="{ item }">
            {{ item.usuario.nombre }}
          </template>

          <template v-slot:item.nota="{ item }">
            <div class="py-3">{{ _limitWords(item.nota, 10) }}</div>
          </template>

          <template v-slot:item.acciones="{ item }">
            <v-btn
              color="primary"
              dark
              small
              depressed
              v-if="_auth.id == item.usuario_id"
              :to="`/editar/nota/${proyecto.id}/${item.id}`"
            >
              <v-icon small>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>

      <div class="mt-3 text-right">
        <v-pagination
          :length="pagination"
          v-model="page"
          class="elevation-0"
        ></v-pagination>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  name: "Notas",
  props: ["proyecto"],
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_SERVER,
      loading: true,
      dialog: false,
      page: 1,
      pagination: null,
      notas: [],
      headers: [
        { text: "Técnico", value: "tecnico" },
        { text: "Fecha", value: "fecha" },
        { text: "Nota", value: "nota" },
        { text: "Acciones", value: "acciones" },
      ],
    };
  },
  mounted() {
    this._loadNotas();
  },
  watch: {
    page() {
      this._loadNotas();
    },
  },
  computed: {
    ...mapState({
      _auth: "auth",
    }),
  },
  methods: {
    _loadNotas() {
      this.loading = true;
      this.gastos = [];
      let params = {
        params: {
          page: this.page,
          proyecto_id: this.proyecto.id,
        },
      };
      axios.get(`/notas`, params).then((res) => {
        if (res.data.status_code == 200) {
          this.notas = res.data.data.data;
          this.pagination = res.data.data.last_page;
          this.loading = false;
        }
      });
    },
    _limitWords(textToLimit, wordLimit = 2, suffix = "...") {
      var finalText = "";
      var text2 = textToLimit.replace(/\s+/g, " ");
      var text3 = text2.split(" ");
      var numberOfWords = text3.length;
      var i = 0;
      if (numberOfWords > wordLimit) {
        for (i = 0; i < wordLimit; i++) {
          finalText = finalText + " " + text3[i] + " ";
        }
        return finalText + suffix;
      } else {
        return textToLimit;
      }
    },
  },
};
</script>
