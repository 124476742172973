<template>
  <div class="usuarios">
    <usuarios-form v-if="usuario" :usuarioProp="usuario"></usuarios-form>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import UsuariosForm from "@/components/UsuariosForm";
export default {
  components: {
    UsuariosForm,
  },
  data: () => ({
    usuario: {},
  }),
  computed: {
    ...mapState({
      _auth: "auth",
    }),
  },
  mounted() {
    axios.get(`/usuario`).then((res) => {
      if (res.data.status_code == 200) {
        this.usuario = res.data.data;
      }
    });
  },
};
</script>
