<template>
  <div class="clientes">
    <clientes-form v-if="cliente" :clienteProp="cliente"></clientes-form>
  </div>
</template>

<script>
import axios from "axios";
import ClientesForm from "@/components/ClientesForm";
export default {
  components: {
    ClientesForm,
  },
  data: () => ({
    id: null,
    cliente: {},
  }),
  mounted() {
    this.id = this.$route.params.id;
    if (this.id) {
      this._getCliente();
    } else {
      this.cliente = "nuevo";
    }
  },
  methods: {
    _getCliente() {
      axios.get(`/clientes/${this.id}`).then((res) => {
        if (res.data.status_code == 200) {
          this.cliente = res.data.data;
        }
      });
    },
  },
};
</script>
