<template>
  <div class="proyecto">
    <proyectos-form :proyectoProp="proyecto" :idProp="id"></proyectos-form>
  </div>
</template>

<script>
import axios from "axios";
import ProyectosForm from "@/components/ProyectosForm";
export default {
  components: {
    ProyectosForm,
  },
  data() {
    return {
      id: null,
      proyecto: null,
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    if (this.id) {
      this._getProyecto();
    } else {
      this.proyecto = "nuevo";
    }
  },
  methods: {
    _getProyecto() {
      axios.get(`proyectos/${this.id}`).then((res) => {
        if (res.data.status_code == 200) {
          this.proyecto = res.data.data;
        }
      });
    },
  },
};
</script>
