<template>
  <div>
    <div class="text-center loader" v-if="loading">
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </div>

    <v-card v-else class="elevation-0">
      <template v-if="usuario_id == null || usuario_id == _auth.id">
        <v-card-title>
          <h2 v-if="id">Editar Nota</h2>
          <h2 v-else>Nueva nota</h2>
          <v-chip v-if="id" class="ma-2" label>
            Proyecto: {{ proyecto.codigo }}
          </v-chip>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-menu
                ref="fecha"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fecha"
                    label="Fecha"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fecha"
                  color="primary"
                  no-title
                  scrollable
                  locale="es"
                >
                </v-date-picker>
              </v-menu>

              <v-text-field
                v-model="nota"
                label="Nota"
                required
                outlined
                dense
              ></v-text-field>

              <v-switch
                v-model="importante"
                :label="`Nota importante`"
              ></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-4 pb-6">
          <v-btn
            color="primary"
            class="px-4"
            large
            depressed
            @click="_saveNota()"
            :loading="loadingButton"
            :disabled="loadingButton"
          >
            <v-icon class="mr-2">
              mdi-cloud-upload
            </v-icon>
            Guardar nota
          </v-btn>
        </v-card-actions>
      </template>

      <v-alert v-else text type="error">
        No tienes permisos para editar esta actividad.
      </v-alert>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  props: ["notaProp", "proyectoId"],
  data() {
    return {
      loading: true,
      loadingButton: false,
      proyecto: false,
      id: null,
      usuario_id: null,
      fecha: null,
      nota: null,
      importante: false,
    };
  },
  computed: {
    ...mapState({
      _auth: "auth",
    }),
  },
  watch: {
    notaProp(val) {
      this._loadNota(val);
    },
  },
  methods: {
    _loadNota(val) {
      if (val) {
        this.proyecto = val.proyecto;
        this.id = val.id;
        this.proyecto_id = val.proyecto.id;
        this.usuario_id = val.usuario_id;
        this.fecha = val.fecha;
        this.nota = val.nota;
        this.importante = val.importante;
      } else {
        this.id = null;
        this.proyecto_id = this.proyectoId;
        this.usuario_id = this._auth.id;
        this.fecha = null;
        this.nota = null;
        this.importante = false;
      }
      this.loading = false;
    },

    async _saveNota() {
      this.loadingButton = true;
      let params = {
        id: this.id,
        proyecto_id: this.proyecto_id,
        usuario_id: this.usuario_id,
        fecha: this.fecha,
        nota: this.nota,
        importante: this.importante,
      };
      const res = await axios.post("nota", params);
      let snackbar = null;
      if (res.data.status_code != 200) {
        snackbar = {
          status: true,
          message: res.data.data,
          type: "error",
        };
      } else {
        snackbar = {
          status: true,
          message: "La nota se ha guardado correctamente.",
          type: "success",
        };
      }

      this.$store.commit("_SNACKBAR", snackbar);
      this.loadingButton = false;
    },
  },
};
</script>
