<template>
  <v-row>
    <v-col cols="12">
      <h2>Datos de administración</h2>
    </v-col>

    <v-col lg="6" cols="sm" class="pb-2">
      <v-card tile class="elevation-0">
        <v-row class="no-gutters">
          <div class="col-auto">
            <div class="primary fill-height" style="width: 5px;"></div>
          </div>
          <div class="col pa-3 py-4 black--text">
            <h5 class="text-truncate primary--text text-uppercase mb-8">
              Análisis horario
            </h5>

            <strong>{{
              _formatFloatHours(_excesoDefecto().diferencia)
            }}</strong>
            horas completadas de
            <strong>{{ proyecto.horas_asignadas }}</strong> asignadas.

            <v-progress-linear
              :value="_excesoDefecto().porcentaje"
              height="25"
              class="my-4"
              :color="_colorBar()"
              striped
            >
              <strong>{{
                _formatFloatPercent(_excesoDefecto().porcentaje)
              }}</strong>
            </v-progress-linear>
          </div>
        </v-row>
      </v-card>
    </v-col>

    <v-col lg="6" cols="sm" class="pb-2">
      <v-card tile class="elevation-0">
        <v-row class="no-gutters">
          <div class="col-auto">
            <div class="primary fill-height" style="width: 5px;"></div>
          </div>
          <div class="col pa-3 py-4 black--text">
            <h5 class="text-truncate primary--text text-uppercase mb-3">
              Análisis económico
            </h5>

            <v-list dense class="ma-0">
              <v-list-item-content>
                <v-list-item-title>
                  Importe Venta
                </v-list-item-title>
                <h4>{{ _formatFloat(proyecto.importe_venta) }}</h4>
              </v-list-item-content>

              <v-list-item-content>
                <v-list-item-title>
                  Gastos Totales
                </v-list-item-title>
                <h4>{{ _formatFloat(_gastosTotales()) }}</h4>
              </v-list-item-content>

              <v-list-item-content>
                <v-list-item-title>
                  Coste Total
                  <v-chip label small color="warning" v-if="errors.costeTotal"
                    >Información incompleta</v-chip
                  >
                </v-list-item-title>
                <h4>{{ _formatFloat(_costeTotal()) }}</h4>
              </v-list-item-content>

              <v-list-item-content>
                <v-list-item-title>
                  Beneficio / Perdida

                  <v-chip label small color="warning" v-if="errors.costeTotal"
                    >Información incompleta</v-chip
                  >
                </v-list-item-title>
                <h4>
                  {{ _formatFloat(proyecto.importe_venta - _costeTotal()) }}
                </h4>
              </v-list-item-content>

              <v-list-item-content>
                <v-list-item-title>
                  Precio/hora real
                </v-list-item-title>
                <h4>{{ _formatFloat(_precioHoraReal()) }}</h4>
              </v-list-item-content>

              <v-list-item-content>
                <v-list-item-title>
                  precio/hora asignado
                </v-list-item-title>
                <h4>{{ _formatFloat(_precioHoraAsignado()) }}</h4>
              </v-list-item-content>
            </v-list>

            <v-alert type="error" dense outlined v-if="errors.costeTotal"
              >Revisa los campos con alertas. Falta valor/año de algún
              técnico.</v-alert
            >
          </div>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
export default {
  props: ["proyecto"],
  data() {
    return {
      loadingButton: false,
      errors: {
        costeTotal: false,
        beneficioPerdida: false,
      },
    };
  },
  computed: {
    ...mapState({
      _datosAdministrativos: "datosAdministrativos",
    }),
  },
  methods: {
    _excesoDefecto() {
      let sum = moment.duration("00:00");
      let horasReales = 0;
      let horasAsignadas = this.proyecto.horas_asignadas;
      this.proyecto.tareas.forEach((tarea) => {
        tarea.actividades.forEach((res) => {
          var startTime = moment(res.hora_inicio, "HH:mm");
          var endTime = moment(res.hora_final, "HH:mm");
          // calculate total duration
          var duration = moment.duration(endTime.diff(startTime));
          // duration in hours
          var hours = parseInt(duration.asHours());
          // duration in minutes
          var minutes = parseInt(duration.asMinutes()) % 60;
          sum.add(moment.duration(`${hours}:${minutes}`));
        });
      });

      horasReales = moment.duration(sum).asHours();

      return {
        diferencia: horasReales,
        porcentaje: ((horasReales * 100) / horasAsignadas).toFixed(2),
      };
    },
    _getGastosKm(res) {
      let datos = this._datosAdministrativos;
      let currentAño = moment().format("YYYY");
      let response = 0;
      let valor = 0;

      datos.forEach((dato, index) => {
        if (index == 2) {
          dato.años.forEach((año) => {
            if (currentAño == año.año) {
              if (año.valor.indexOf(",") > -1) {
                valor = año.valor.replace(/,/g, ".");
              }

              response = parseInt(res.km) * parseFloat(valor);
            }
          });
        }
      });

      return response;
    },
    _gastosTotales() {
      let gastosTotales = 0;
      let gastosKm = 0;
      let currentGasto = 0;
      this.proyecto.gastos.forEach((res) => {
        if (res.gastos) {
          if (res.gastos.indexOf(",") > -1) {
            currentGasto = res.gastos.replace(/,/g, ".");
          } else {
            currentGasto = currentGasto + res.gastos;
          }
          gastosTotales = gastosTotales + parseFloat(currentGasto);
        }

        if (res.km) {
          gastosKm = gastosKm + this._getGastosKm(res);
        }
      });

      return (
        parseFloat(this.proyecto.subcontratacion) +
        gastosTotales +
        gastosKm
      ).toFixed(2);
    },
    _privateGetActividadesTecnico(tecnico) {
      let sum = moment.duration("00:00");
      let realesTotales = 0;
      this.proyecto.tareas.forEach((tarea) => {
        tarea.actividades.forEach((act) => {
          if (act.usuario.id == tecnico.usuario_id) {
            var startTime = moment(act.hora_inicio, "HH:mm");
            var endTime = moment(act.hora_final, "HH:mm");
            // calculate total duration
            var duration = moment.duration(endTime.diff(startTime));
            // duration in hours
            var hours = parseInt(duration.asHours());
            // duration in minutes
            var minutes = parseInt(duration.asMinutes()) % 60;
            sum.add(moment.duration(`${hours}:${minutes}`));
          }
        });
      });
      realesTotales = moment.duration(sum).asHours();
      return realesTotales;
    },
    _costeTotal() {
      let sum = 0;
      let reales = 0;
      let añoValor = 0;

      this.proyecto.tecnicos.forEach((tecnico) => {
        reales = this._privateGetActividadesTecnico(tecnico);
        tecnico.usuario.años.forEach((año) => {
          if (año.año == moment(this.proyecto.fecha_inicio).format("YYYY")) {
            añoValor = parseFloat(año.valor);
          }
        });
        sum = sum + reales * añoValor;
      });
      if (añoValor == 0) this.errors.costeTotal = true;
      return parseFloat(this._gastosTotales()) + parseFloat(sum);
    },
    _precioHoraReal() {
      if (!this._excesoDefecto().diferencia) return 0;
      return (
        (parseFloat(this.proyecto.importe_venta) - this._gastosTotales()) /
        this._excesoDefecto().diferencia
      ).toFixed(2);
    },
    _precioHoraAsignado() {
      return (
        (parseFloat(this.proyecto.importe_venta) -
          parseFloat(this.proyecto.subcontratacion)) /
        parseFloat(this.proyecto.horas_asignadas)
      ).toFixed(2);
    },
    _colorBar() {
      let status = this._excesoDefecto().porcentaje;
      let color = "success";
      if (status < 80) {
        color = "success";
      } else if (status < 90) {
        color = "warning";
      } else {
        color = "error";
      }
      return color;
    },
    _formatFloat(val) {
      if (!val) return "0,00 €";

      if (isNaN(val)) return "-";
      return (
        (val * 1)
          .toFixed(2) // always two decimal digits
          .replace(".", ",") // replace decimal point character with ,
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"
      );
    },
    _formatFloatHours(val) {
      if (!val) return "0,00 €";

      return (val * 1)
        .toFixed(2) // always two decimal digits
        .replace(".", ",") // replace decimal point character with ,
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    },

    _formatFloatPercent(val) {
      if (!val) return "0,00 %";

      return (
        (val * 1)
          .toFixed(2) // always two decimal digits
          .replace(".", ",") // replace decimal point character with ,
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + "%"
      );
    },
  },
};
</script>
