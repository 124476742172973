<template>
  <div>
    <div class="text-center loader" v-if="loading">
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </div>

    <v-card tile class="elevation-0" v-else>
      <v-card-title>
        <h2 v-if="id">Ajustes generales</h2>
        <h2 v-else>Nuevo</h2>

        <v-chip class="ma-2 text-uppercase" v-if="slug" label
          >{{ slug.replace("-", " ") }}
        </v-chip>
      </v-card-title>

      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card class="pa-4">
          <template>
            <v-card-text class="text-center">
              <h3 class="mb-3">Cargando tareas...</h3>
              <v-progress-circular
                :size="40"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-card-text>
          </template>
        </v-card>
      </v-dialog>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-form>
              <v-text-field
                v-model="nombre"
                label="Nombre"
                hide-details="true"
                outlined
                required
                :disabled="!_auth.a"
              ></v-text-field>
            </v-form>
          </v-col>

          <!-- Asignar Años -->
          <v-col cols="12" v-if="slug == 'datos-administrativos'">
            <h3 class="my-0">Asignar años</h3>
            <v-card class="pa-6 mb-3" outlined>
              <v-alert v-if="!años.length" dense text type="info">
                No has asignado ningún coste.
              </v-alert>

              <div
                v-else
                v-for="(item, index) in años"
                :key="index"
                :id="`año_${index}`"
              >
                <v-row>
                  <v-col cols="7" class="pb-0 mb-5">
                    <v-text-field
                      v-model="años[index].año"
                      label="Año"
                      hide-details="true"
                      dense
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" class="pb-0">
                    <v-text-field
                      v-model="años[index].valor"
                      label="Valor"
                      hide-details="true"
                      dense
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1" class="pb-0">
                    <v-btn
                      color="white"
                      depressed
                      class="mr-2 delete"
                      dark
                      @click="_removeAño(index)"
                    >
                      <v-icon small color="black">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

                <v-switch
                  v-model="años[index].mostrar_meses"
                  label="Valores por meses del año"
                ></v-switch>

                <template v-if="años[index].mostrar_meses">
                  <v-row v-for="(mes, i) in meses" :key="i">
                    <v-col cols="7" class="pb-0 input-meses">
                      <v-text-field
                        :label="mes"
                        hide-details="true"
                        dense
                        outlined
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" class="pb-0">
                      <v-text-field
                        v-model="años[index][mes]"
                        label="Valor"
                        hide-details="true"
                        dense
                        outlined
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="my-4" v-if="i == 11">
                      <v-divider />
                    </v-col>
                  </v-row>
                </template>
              </div>
              <v-btn
                color="black"
                class="my-2"
                dark
                small
                depressed
                @click="_addAño()"
              >
                <v-icon small class="mr-1">
                  mdi-pencil
                </v-icon>
                Añadir año
              </v-btn>
            </v-card>
          </v-col>
          <!-- Asignar años -->

          <!-- Asignar tareas -->
          <v-col cols="12" v-if="slug == 'tipo-proyectos'">
            <h3 class="my-0">Asignar tareas</h3>
            <v-card class="pa-6 mb-3" outlined>
              <v-alert
                v-if="!tareas.length && dialog == false"
                dense
                text
                type="info"
              >
                No has seleccionado ninguna tarea por defecto para este tipo de
                proyecto.
              </v-alert>

              <div
                v-else
                v-for="(item, index) in tareas"
                :key="index"
                :id="`tarea_${index}`"
              >
                <v-row>
                  <v-col cols="11" class="pb-0">
                    <v-select
                      v-model="tareas[index].tipo_tarea_id"
                      name="tareas[][tipo_tarea_id]"
                      :items="_tipoTareas"
                      dense
                      label="Tarea"
                      outlined
                      item-text="nombre"
                      item-value="id"
                    ></v-select>
                  </v-col>

                  <v-col cols="1" class="pb-0">
                    <v-btn
                      color="white"
                      depressed
                      class="mr-2 delete"
                      dark
                      @click="_removeTarea(index)"
                    >
                      <v-icon small color="black">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <v-btn
                color="black"
                class="my-2"
                dark
                small
                depressed
                @click="_addTarea()"
              >
                <v-icon small class="mr-1">
                  mdi-pencil
                </v-icon>
                Añadir tarea
              </v-btn>
            </v-card>
          </v-col>
          <!-- Asignar tareas -->
        </v-row>
      </v-card-text>
      <v-card-actions class="px-4 pb-6">
        <v-btn
          color="primary"
          class="px-4"
          large
          depressed
          @click="_saveDatos()"
          :loading="loadingButton"
          :disabled="loadingButton"
        >
          <v-icon class="mr-2">
            mdi-cloud-upload
          </v-icon>
          Guardar Datos
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  props: ["datosProp"],
  data() {
    return {
      loading: true,
      dialog: false,
      loadingButton: false,
      id: null,
      slug: null,
      nombre: null,
      meses: [
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre",
      ],
      años: [],
      año: {
        id: null,
        año: null,
        valor: null,
      },
      tareas: [],
      tarea: {
        id: null,
        proyecto_id: null,
        tipo_tarea_id: null,
        fecha_inicio: null,
        fecha_final: null,
        horas: null,
      },
      error: false,
    };
  },
  computed: {
    ...mapState({
      _auth: "auth",
      _tipoTareas: "tipoTareas",
    }),
  },
  watch: {
    datosProp(val) {
      if (val != "nuevo") this._loadDatos(val);
      else {
        this.slug = this.$route.params.slug;
      }
      if (this.slug == "tipo-proyectos") {
        this._loadTareas();
      }

      this.loading = false;
    },
  },
  methods: {
    _loadDatos(val) {
      if (val) {
        this.id = val.id;
        this.nombre = val.nombre;
        this.slug = this.$route.params.slug;
        this.años = val.años;
      } else {
        this.slug = this.$route.params.slug;
      }
    },
    _loadTareas() {
      this.dialog = true;
      this.tareas = [];
      axios.get(`proyecto-tareas/${this.id}`).then((res) => {
        if (res.data.status_code == 200) {
          this.tareas = res.data.data;
        }
        this.dialog = false;
      });
    },
    _addTarea(tipoTareaId = null) {
      this.tarea = {
        proyecto_id: this.id,
        tipo_tarea_id: tipoTareaId,
        fecha_inicio: null,
        fecha_final: null,
        horas: null,
      };
      this.tareas.push(this.tarea);
    },
    _removeTarea(el) {
      this.tareas.map((item, i) => {
        if (i == el) {
          if (item.id) {
            item["del"] = item.id;
            document.getElementById(`tarea_${el}`).style.display = "none";
          } else {
            this.tareas.splice(i, 1);
          }
        }
      });
    },
    _addAño() {
      this.año = {
        año: null,
        valor: null,
        mostrar_meses: false,
        enero: null,
        febrero: null,
        marzo: null,
        abril: null,
        mayo: null,
        junio: null,
        julio: null,
        agosto: null,
        septiembre: null,
        octubre: null,
        noviembre: null,
        diciembre: null,
      };
      this.años.push(this.año);
    },
    _removeAño(el) {
      this.años.map((item, i) => {
        if (i == el) {
          if (item.id) {
            item["del"] = item.id;
            document.getElementById(`año_${el}`).style.display = "none";
          } else {
            this.años.splice(i, 1);
          }
        }
      });
    },
    _saveDatos() {
      this.loadingButton = true;
      this.error = false;
      let params = {
        id: this.id,
        nombre: this.nombre,
        tareas: this.tareas,
        años: this.años,
      };

      axios.post(`/${this.slug}`, params).then((res) => {
        let snackbar = null;
        if (res.data.status_code != 200) {
          let errors = res.data.data;
          snackbar = {
            status: true,
            message: errors,
            type: "error",
          };
        } else {
          this.$store.dispatch("_datosAdministrativos");

          snackbar = {
            status: true,
            message: "Los datos se han guardado correctamente.",
            type: "success",
          };

          if (this.slug == "tipo-tareas") this.$store.dispatch("_tipoTareas");
          if (this.slug == "tipo-esquemas")
            this.$store.dispatch("_tipoEsquemas");
          if (this.slug == "tipo-actividades")
            this.$store.dispatch("_tipoActividades");
          if (this.slug == "tipo-proyectos")
            this.$store.dispatch("_tipoProyectos");
        }

        this.$store.commit("_SNACKBAR", snackbar);
        this.loadingButton = false;
      });
    },
  },
};
</script>

<style lang="scss">
.input-meses {
  text-transform: uppercase;
}
</style>
