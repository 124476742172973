<template>
  <div>
    <v-container>
      <v-timeline dense clipped>
        <v-timeline-item
          v-for="nota in notas"
          :key="nota.id"
          :color="nota.importante ? 'error' : 'grey'"
          small
        >
          <v-row justify="space-between">
            <v-col cols="12">
              <v-card class="elevation-1">
                <v-card-text>
                  <div>
                    <v-chip
                      class="text-uppercase mb-3"
                      color="error"
                      label
                      x-small
                      v-if="nota.importante"
                      >IMPORTANTE
                    </v-chip>
                  </div>
                  <div class="text--primary mb-4">
                    {{ nota.nota }}
                  </div>

                  <strong>
                    {{ nota.usuario.nombre }}
                  </strong>
                  -
                  {{ nota.fecha }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Notas",
  props: ["notas"],
  data() {
    return {
      loading: true,
    };
  },
};
</script>
