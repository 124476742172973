<template>
  <div>
    <div class="text-center loader" v-if="loading">
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </div>

    <v-card tile class="elevation-0" v-else>
      <v-card-title>
        <h2 v-if="id">Editar datos de usuario</h2>
        <h2 v-else>Nuevo Usuario</h2>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12">
            <v-form>
              <v-text-field
                v-model="codigo"
                label="Código"
                dense
                outlined
                required
                :disabled="!_auth.a"
              ></v-text-field>

              <v-text-field
                v-model="nombre"
                label="Nombre"
                dense
                outlined
                required
                :disabled="!_auth.a"
              ></v-text-field>

              <v-text-field
                v-model="email"
                label="Email"
                dense
                outlined
                required
                :disabled="!_auth.a"
              ></v-text-field>
              <template v-if="_auth.a">
                <v-alert icon="mdi-shield-lock-outline" dense text type="info">
                  ¿Este usuario tiene permisos de administrador?
                </v-alert>
                <v-switch v-model="es_admin" label="Administrador"></v-switch>
              </template>

              <h3 class="my-3">Cambiar contraseña</h3>

              <v-text-field
                v-model="password"
                label="Nueva contraseña"
                type="password"
                hide-details="true"
                dense
                outlined
                required
              ></v-text-field>

              <!-- Asignar Años -->
              <template v-if="_auth.a">
                <h3 class="my-0 mt-10">Asignar coste/hora</h3>
                <v-card class="pa-6 mb-3" outlined>
                  <v-alert v-if="!años.length" dense text type="info">
                    No has asignado ningún coste.
                  </v-alert>

                  <div
                    v-else
                    v-for="(item, index) in años"
                    :key="index"
                    :id="`año_${index}`"
                  >
                    <v-row>
                      <v-col cols="7" class="pb-0 mb-5">
                        <v-text-field
                          v-model="años[index].año"
                          label="Año"
                          hide-details="true"
                          dense
                          outlined
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4" class="pb-0">
                        <v-text-field
                          v-model="años[index].valor"
                          label="Coste"
                          hide-details="true"
                          dense
                          outlined
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="1" class="pb-0">
                        <v-btn
                          color="white"
                          depressed
                          class="mr-2 delete"
                          dark
                          @click="_removeAño(index)"
                        >
                          <v-icon small color="black">
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                  <v-btn
                    color="black"
                    class="my-2"
                    dark
                    small
                    depressed
                    @click="_addAño()"
                  >
                    <v-icon small class="mr-1">
                      mdi-pencil
                    </v-icon>
                    Añadir año
                  </v-btn>
                </v-card>
              </template>
              <!-- Asignar años -->
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-4 pb-6">
        <v-btn
          color="primary"
          class="px-4"
          large
          depressed
          @click="_saveUsuario()"
          :loading="loadingButton"
          :disabled="loadingButton"
        >
          <v-icon class="mr-2">
            mdi-cloud-upload
          </v-icon>
          Guardar Usuario
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  props: ["usuarioProp"],
  data() {
    return {
      loading: true,
      loadingButton: false,
      id: null,
      nombre: null,
      email: null,
      password: null,
      es_admin: null,
      codigo: null,
      años: [],
      año: {
        id: null,
        usuario_id: null,
        año: null,
        valor: null,
      },
      error: false,
    };
  },
  computed: {
    ...mapState({
      _auth: "auth",
      _tecnicos: "tecnicos",
    }),
  },
  watch: {
    usuarioProp(val) {
      if (val != "nuevo") this._loadUsuario(val);
      this.loading = false;
    },
  },
  methods: {
    _loadUsuario(val) {
      if (val) {
        this.id = val.id;
        this.nombre = val.nombre;
        this.email = val.email;
        this.es_admin = val.es_admin;
        this.codigo = val.codigo;
        this.años = val.años;
      }
    },
    _addAño() {
      this.año = {
        usuario_id: null,
        año: null,
        valor: null,
      };
      this.años.push(this.año);
    },
    _removeAño(el) {
      this.años.map((item, i) => {
        if (i == el) {
          if (item.id) {
            item["del"] = item.id;
            document.getElementById(`año_${el}`).style.display = "none";
          } else {
            this.años.splice(i, 1);
          }
        }
      });
    },
    _saveUsuario() {
      this.loadingButton = true;
      this.error = false;
      let params = {
        id: this.id,
        nombre: this.nombre,
        email: this.email,
        password: this.password,
        es_admin: this.es_admin,
        codigo: this.codigo,
        años: this.años,
      };

      axios.post(`/usuarios`, params).then((res) => {
        let snackbar = null;
        if (res.data.status_code != 200) {
          let errors = res.data.data;
          snackbar = {
            status: true,
            message: errors,
            type: "error",
          };
        } else {
          snackbar = {
            status: true,
            message: "Los datos del usuario se han guardado correctamente.",
            type: "success",
          };

          // set new token
          if (res.data.access_token) {
            let authObject = {
              token: res.data.access_token,
              id: res.data.data.id,
              nombre: res.data.data.nombre,
              a: res.data.data.es_admin,
            };
            this.$cookies.set("auth", authObject);
            this.$store.commit("_AUTH", authObject);
          }
        }

        this.$store.commit("_SNACKBAR", snackbar);
        this.loadingButton = false;
      });
    },
  },
};
</script>
