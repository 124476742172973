<template>
  <div>
    <div class="text-center loader" v-if="loading">
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </div>

    <v-card v-else class="elevation-0">
      <template v-if="usuario_id == null || usuario_id == _auth.id">
        <v-card-title>
          <h2 v-if="id">Editar Gasto</h2>
          <h2 v-else>Nuevo gasto</h2>
          <v-chip v-if="id" class="ma-2" label>
            Proyecto: {{ proyecto.codigo }}
          </v-chip>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-menu
                ref="fecha"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fecha"
                    label="Fecha"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fecha"
                  color="primary"
                  no-title
                  scrollable
                  locale="es"
                >
                </v-date-picker>
              </v-menu>

              <v-text-field
                v-model="km"
                label="Kilometraje"
                hide-details="true"
                required
                outlined
                dense
              ></v-text-field>

              <!-- <v-switch v-model="concepto" :label="`Gastos de concepto`"></v-switch> -->

              <v-alert text icon="mdi-alert-outline" type="info" class="mt-4">
                Si quieres asignar un gasto y su concepto
              </v-alert>

              <v-text-field
                v-model="concepto"
                label="Concepto"
                required
                outlined
                dense
              ></v-text-field>

              <v-text-field
                v-model="gastos"
                label="Gastos"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-4 pb-6">
          <v-btn
            color="primary"
            class="px-4"
            large
            depressed
            @click="_saveGasto()"
            :loading="loadingButton"
            :disabled="loadingButton"
          >
            <v-icon class="mr-2">
              mdi-cloud-upload
            </v-icon>
            Guardar gasto
          </v-btn>
        </v-card-actions>
      </template>

      <v-alert v-else text type="error">
        No tienes permisos para editar esta actividad.
      </v-alert>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  props: ["gasto", "proyectoId"],
  data() {
    return {
      loading: true,
      loadingButton: false,
      proyecto: false,
      id: null,
      usuario_id: null,
      fecha: null,
      km: null,
      concepto: 0,
      gastos: null,
    };
  },
  computed: {
    ...mapState({
      _auth: "auth",
    }),
  },
  watch: {
    gasto(val) {
      this._loadGasto(val);
    },
  },
  methods: {
    _loadGasto(val) {
      if (val) {
        this.proyecto = val.proyecto;
        this.id = val.id;
        this.proyecto_id = val.proyecto.id;
        this.usuario_id = val.usuario_id;
        this.fecha = val.fecha;
        this.km = val.km;
        this.concepto = val.concepto;
        this.gastos = val.gastos;
      } else {
        this.proyecto_id = this.proyectoId;
        this.id = null;
        this.usuario_id = this._auth.id;
        this.fecha = null;
        this.km = null;
        this.concepto = null;
        this.gastos = null;
      }
      this.loading = false;
    },

    async _saveGasto() {
      this.loadingButton = true;
      let params = {
        id: this.id,
        proyecto_id: this.proyecto_id,
        usuario_id: this.usuario_id,
        fecha: this.fecha,
        km: this.km,
        concepto: this.concepto,
        gastos: this.gastos,
      };

      const actividad = await axios.post("gasto", params);
      let snackbar = null;
      if (actividad.data.status_code != 200) {
        snackbar = {
          status: true,
          message: actividad.data.data,
          type: "error",
        };
      } else {
        snackbar = {
          status: true,
          message: "El gasto se ha guardado correctamente.",
          type: "success",
        };
      }

      this.$store.commit("_SNACKBAR", snackbar);
      this.loadingButton = false;
    },
  },
};
</script>
