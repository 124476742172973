<template>
  <div class="tecnicos">
    <datos-form v-if="datos" :datosProp="datos"></datos-form>
  </div>
</template>

<script>
import axios from "axios";
import DatosForm from "@/components/DatosForm";
export default {
  components: {
    DatosForm,
  },
  data: () => ({
    id: null,
    slug: null,
    datos: {},
  }),
  mounted() {
    this.id = this.$route.params.id;
    this.slug = this.$route.params.slug;
    if (this.id) {
      this._getDatos();
    } else {
      this.datos = "nuevo";
    }
  },
  methods: {
    _getDatos() {
      axios.get(`/${this.slug}/${this.id}`).then((res) => {
        if (res.data.status_code == 200) {
          this.datos = res.data.data;
        }
      });
    },
  },
};
</script>
