<template>
  <v-card class="elevation-0" :class="{ transparent: fromDetalle }">
    <v-card-title>
      <h3>Fuera de plazo</h3>
    </v-card-title>
    <v-card-text>
      <p>Describe los detalles del porqué está fuera de plazo.</p>

      <v-row>
        <v-col cols="12">
          <v-menu
            ref="fecha_final_prevista"
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fecha_final_prevista"
                label="Fecha final prevista"
                readonly
                hide-details="true"
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fecha_final_prevista"
              color="primary"
              no-title
              scrollable
              locale="es"
              hide-details="true"
            >
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12">
          <v-textarea outlined dense v-model="causa" label="Causa"></v-textarea>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="px-4 pb-6">
      <v-btn
        color="primary"
        class="px-4"
        large
        depressed
        @click="_save()"
        :loading="loadingButton"
        :disabled="loadingButton"
      >
        Guardar motivos fuera de plazo
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  props: ["proyectoId", "fechaFinalPlanificada", "fromDetalle"],
  data() {
    return {
      loadingButton: false,
      fecha_final_prevista: null,
      causa: null,
    };
  },
  methods: {
    _save() {
      this.loadingButton = true;
      let params = {
        proyecto_id: this.proyectoId,
        fecha_final_prevista: this.fecha_final_prevista,
        causa: this.causa,
      };

      axios.post("fuera-plazo", params).then((res) => {
        if (res.data.status_code != 200) {
          let snackbar = {
            status: true,
            message: res.data.data,
            type: "error",
          };
          this.$store.commit("_SNACKBAR", snackbar);
        } else {
          let snackbar = {
            status: true,
            message: "El proyecto se ha marcado como fuera de plazo.",
            type: "success",
          };
          this.$store.commit("_SNACKBAR", snackbar);

          this.$emit("saveFueraPlazo");
        }
        this.loadingButton = false;
      });
    },
  },
};
</script>
