import { render, staticRenderFns } from "./Tecnico.vue?vue&type=template&id=1aa79cfb&scoped=true"
import script from "./Tecnico.vue?vue&type=script&lang=js"
export * from "./Tecnico.vue?vue&type=script&lang=js"
import style0 from "./Tecnico.vue?vue&type=style&index=0&id=1aa79cfb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1aa79cfb",
  null
  
)

export default component.exports