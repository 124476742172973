<template>
  <v-card class="elevation-0">
    <v-card-title>
      <h3>Cambiar evolución del proyecto</h3>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-text-field
            outlined
            dense
            v-model="evolucion"
            hide-details="true"
            label="Cambiar evolución"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="px-4 pb-6">
      <v-btn
        color="primary"
        class="px-4 ml-2"
        large
        depressed
        @click="_save()"
        :loading="loadingButton"
        :disabled="loadingButton"
      >
        <v-icon class="mr-2">
          mdi-cloud-upload
        </v-icon>
        Guardar evolución del proyecto
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  props: ["proyecto"],
  data() {
    return {
      loadingButton: false,
      evolucion: null,
    };
  },
  methods: {
    _save() {
      this.loadingButton = true;
      let params = {
        id: this.proyecto.id,
        evolucion: this.evolucion,
      };

      axios.post(`cambiar-evolucion`, params).then((res) => {
        if (res.data.status_code != 200) {
          let snackbar = {
            status: true,
            message: res.data.data,
            type: "error",
          };
          this.$store.commit("_SNACKBAR", snackbar);
        } else {
          let snackbar = {
            status: true,
            message: "La evolución del proyecto ha sido cambiada.",
            type: "success",
          };
          this.$store.commit("_SNACKBAR", snackbar);
          this.$emit("saveEvolucion", res.data.data);
        }
        this.loadingButton = false;
      });
    },
  },
};
</script>
