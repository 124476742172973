<template>
  <v-row class="text-center" no-gutters>
    <template v-if="proyecto">
      <v-alert v-if="!proyecto.tareas.length" type="error" outlined dense>
        Debes planificar cada tarea del proyecto para visualizar el cronograma.
      </v-alert>

      <v-col cols="12" v-for="(tarea, i) in proyecto.tareas" :key="i">
        <div
          class="text-caption primary-text mt-5 mb-2 text-left text-uppercase"
        >
          <v-icon>mdi-menu-down</v-icon>
          {{ tarea.tipo_tarea.nombre }}
        </div>

        <div class="crono-col-container">
          <!-- :style="`flex: 0 0 ${100 / monthsDifference}%;`" -->
          <div
            class="crono-col"
            v-for="(month, i) in _monthsDifference()"
            :key="i"
            @click="_showDialog(month, tarea)"
            :style="
              monthsDifference < 24
                ? `flex: 0 0 ${100 / (monthsDifference + 1)}%;`
                : `flex: 0 0 110px;`
            "
          >
            <v-card class="pa-0" outlined tile>
              <div class="col py-3 text-left">
                <h5
                  class="text-truncate text-uppercase mb-0"
                  style="font-size: 12px;"
                >
                  {{ monthNames[month.getMonth()] }}

                  <v-chip class="ml-1 text-uppercase" x-small>
                    {{ moment(month).year() }}
                  </v-chip>
                </h5>
              </div>

              <v-chip
                :color="
                  _monthIsSelected(month, tarea, proyecto.planificacion)
                    ? 'primary'
                    : 'grey lighten-3'
                "
                label
                :text-color="
                  _monthIsSelected(month, tarea, proyecto.planificacion)
                    ? 'white'
                    : 'black'
                "
              >
                <span
                  >Prev:
                  <strong>{{
                    _formatFloatHours(
                      _monthIsSelected(month, tarea, proyecto.planificacion)
                        ? _divisionHoras(
                            tarea,
                            tarea.fecha_inicio,
                            tarea.fecha_final,
                            proyecto.planificacion
                          )
                        : "0"
                    )
                  }}</strong></span
                >
              </v-chip>

              <v-chip
                :color="
                  _getActividadesHoras(month, tarea)
                    ? 'green lighten-1'
                    : 'grey lighten-3'
                "
                label
                :text-color="
                  _getActividadesHoras(month, tarea) ? 'white' : 'black'
                "
              >
                Real:
                <strong>
                  {{
                    _formatFloatHours(_getActividadesHoras(month, tarea))
                  }}</strong
                >
              </v-chip>
            </v-card>
          </div>

          <!-- extra datos -->
          <div
            class="crono-col"
            :style="
              monthsDifference < 24
                ? `flex: 0 0 ${100 / (monthsDifference + 1)}%;`
                : `flex: 0 0 110px;`
            "
          >
            <v-card class="pa-0" outlined tile>
              <div class="col py-3 text-left white--text black">
                <h5
                  class="text-truncate text-uppercase mb-0"
                  style="font-size: 12px;"
                >
                  RESUMEN
                </h5>
              </div>

              <v-chip label color="white">
                Total horas:
                <strong>{{
                  _formatFloat(_getActividadesTotales(tarea))
                }}</strong>
              </v-chip>

              <v-chip label color="white">
                Cumplimento:
                <strong>
                  {{
                    _getActividadesCumplimiento(
                      tarea,
                      _getActividadesTotales(tarea),
                      proyecto.planificacion
                    )
                  }}</strong
                >
              </v-chip>
            </v-card>
          </div>
          <!-- extra datos -->
        </div>
      </v-col>

      <v-dialog v-model="dialog" max-width="450">
        <v-card class="pa-3">
          <v-card-title class="pa-2" style="font-size: 17px;">
            Actividades de {{ currentMes }}
            <v-chip x-small class="ml-3 text-uppercase">{{ tareaMes }} </v-chip>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="actividadesMes"
            class="elevation-0"
            loading-text="Cargando..."
            hide-default-footer
          >
          </v-data-table>
        </v-card>
      </v-dialog>
    </template>
  </v-row>
</template>

<script>
import moment from "moment";
export default {
  name: "Cronograma",
  props: ["proyectoProp"],
  data() {
    return {
      dialog: false,
      proyecto: false,
      actividadesMes: [],
      tareaMes: null,
      currentMes: null,
      fecha_inicio: null,
      fecha_final: null,
      monthsDifference: 0,
      months: [],
      monthNames: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      headers: [
        { text: "Técnico", value: "usuario.nombre" },
        { text: "Fecha", value: "fecha" },
        { text: "Horas", value: "horas" },
      ],
    };
  },
  mounted() {
    this.proyecto = this.proyectoProp;
    this.fecha_inicio = new Date(this.proyecto.fecha_inicio);
    this.fecha_final = new Date(this.proyecto.fecha_final);
  },
  watch: {
    proyectoProp(val) {
      this.proyecto = val;
      this.fecha_inicio = new Date(this.proyecto.fecha_inicio);
      this.fecha_final = new Date(this.proyecto.fecha_final);
    },
  },
  methods: {
    _divisionHoras(tarea, inicio, final, planificacion) {
      if (planificacion) {
        let diff = moment(final, "YYYY-MM")
          .add(1, "M")
          .diff(moment(inicio, "YYYY-MM"), "months", true);

        return (tarea.horas / diff).toFixed(2);
      } else {
        let diff = moment(this.proyecto.fecha_final, "YYYY-MM")
          .add(1, "M")
          .diff(moment(this.proyecto.fecha_inicio, "YYYY-MM"), "months", true);

        return (this.proyecto.horas_asignadas / diff).toFixed(2);
      }
    },
    _monthIsSelected(month, tarea, planificacion) {
      if (planificacion) {
        let from = moment(tarea.fecha_inicio, "YYYY-MM");
        let to = moment(tarea.fecha_final, "YYYY-MM").add(1, "M");
        let check = moment(month, "YYYY-MM");

        if (check.isBetween(from, to)) {
          return true;
        }
      } else {
        return true;
      }
    },
    _monthsDifference() {
      let months;
      let diff;
      let monthsArray = [];
      let minDate = moment(this.fecha_inicio);
      let maxDate = moment(this.fecha_final);
      //compararmos la fecha maxima entre todas las actividades.
      this.proyecto.tareas.forEach((tarea) => {
        tarea.actividades.forEach((act) => {
          if (moment(act.fecha) > maxDate) {
            maxDate = act.fecha;
          }
          if (moment(act.fecha) < minDate) {
            minDate = act.fecha;
          }
        });
      });

      // comparamos si la ultima actividad es menor que la fecha inicial del proyecto.
      if (moment(minDate) > moment(this.fecha_inicio)) {
        minDate = this.fecha_inicio;
      }

      // comparamos si la ultima actividad es menor que la fecha final del proyecto.
      if (moment(maxDate) < moment(this.fecha_final)) {
        maxDate = this.fecha_final;
      }

      // asignamos valores de fechas.
      let fecha_inicio = new Date(minDate);
      let fecha_final = new Date(maxDate);

      months = (fecha_final.getFullYear() - fecha_inicio.getFullYear()) * 12;
      months -= fecha_inicio.getMonth();
      months += fecha_final.getMonth();
      diff = months <= 0 ? 0 : months;

      for (let i = 0; i <= diff; i++) {
        // mostramos al menos un mes.
        if (i == 0) {
          monthsArray.push(
            new Date(fecha_inicio.setMonth(fecha_inicio.getMonth()))
          );
        } else {
          monthsArray.push(
            new Date(fecha_inicio.setMonth(fecha_inicio.getMonth() + 1))
          );
        }
      }
      this.monthsDifference = monthsArray.length;
      return monthsArray;
    },
    _getActividadesHoras(month, tarea) {
      let sum = moment.duration("00:00");
      tarea.actividades.forEach((res) => {
        if (moment(res.fecha).isSame(month, "month")) {
          var startTime = moment(res.hora_inicio, "HH:mm");
          var endTime = moment(res.hora_final, "HH:mm");
          // calculate total duration
          var duration = moment.duration(endTime.diff(startTime));
          // duration in hours
          var hours = parseInt(duration.asHours());
          // duration in minutes
          var minutes = parseInt(duration.asMinutes()) % 60;
          sum.add(moment.duration(`${hours}:${minutes}`));
        }
      });

      return moment.duration(sum).asHours();

      // return moment.utc(sum.as("milliseconds")).format("HH:mm"); //h [hrs], m [min]
    },
    _showDialog(month, tarea) {
      let array = [];
      tarea.actividades.forEach((res) => {
        if (moment(res.fecha).isSame(month, "month")) {
          var startTime = moment(res.hora_inicio, "HH:mm");
          var endTime = moment(res.hora_final, "HH:mm");
          // calculate total duration
          var duration = moment.duration(endTime.diff(startTime));
          // duration in hours
          var hours = duration.asHours();
          res["horas"] = hours;
          array.push(res);
        }
      });
      this.actividadesMes = array;
      this.currentMes = this.monthNames[month.getMonth()];
      this.tareaMes = tarea.tipo_tarea.nombre;
      if (this.actividadesMes.length) this.dialog = true;
    },
    _getActividadesTotales(tarea) {
      let sum = moment.duration("00:00");
      tarea.actividades.forEach((res) => {
        var startTime = moment(res.hora_inicio, "HH:mm");
        var endTime = moment(res.hora_final, "HH:mm");
        // calculate total duration
        var duration = moment.duration(endTime.diff(startTime));
        // duration in hours
        var hours = parseInt(duration.asHours());
        // duration in minutes
        var minutes = parseInt(duration.asMinutes()) % 60;
        sum.add(moment.duration(`${hours}:${minutes}`));
      });

      return moment.duration(sum).asHours();

      // return moment.utc(sum.as("milliseconds")).format("HH:mm"); //h [hrs], m [min]
    },
    _getActividadesCumplimiento(tarea, horas, planificacion) {
      let response = 0;
      if (planificacion) {
        if (tarea.horas == 0) return "Datos incompletos.";
        if (horas) response = ((horas * 100) / tarea.horas).toFixed(2);
        return this._formatFloat(response);
      } else {
        if (this.proyecto.horas_asignadas == 0) return "Datos incompletos.";
        if (horas)
          response = ((horas * 100) / this.proyecto.horas_asignadas).toFixed(2);
        return this._formatFloat(response);
      }
    },
    _formatFloat(val) {
      if (!val) return "0,00 %";

      return (
        (val * 1)
          .toFixed(2) // always two decimal digits
          .replace(".", ",") // replace decimal point character with ,
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " %"
      );
    },
    _formatFloatHours(val) {
      if (!val) return "0,00 %";

      return (val * 1)
        .toFixed(2) // always two decimal digits
        .replace(".", ",") // replace decimal point character with ,
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-chip--label {
  width: 100%;
  border-radius: 0 !important;
}

.crono-col-container {
  display: flex;
  align-items: stretch;
  justify-items: flex-start;
  flex-wrap: nowrap; //wrap
  justify-content: flex-start;
  overflow-x: scroll;
}

.v-card {
  height: 100%;
}
</style>
