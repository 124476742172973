<template>
  <div class="proyectos">
    <h1>Proyectos de Técnico</h1>

    <v-toolbar class="mb-2" color="white" flat>
      <v-select
        v-if="_estados"
        v-model="estado_id"
        :items="_estados"
        label="Estados"
        @change="_loadProyectos()"
        clearable
        flat
        solo
        hide-details
        dense
        item-text="nombre"
        item-value="id"
      ></v-select>
      <v-spacer></v-spacer>

      <v-select
        v-if="_clientes"
        v-model="cliente_id"
        :items="_clientes"
        label="Clientes"
        @change="_loadProyectos()"
        clearable
        flat
        solo
        hide-details
        dense
        item-text="nombre_comercial"
        item-value="id"
      ></v-select>
      <v-spacer></v-spacer>
      <v-select
        v-model="sortData"
        :items="sortDataOptions"
        label="Ordenar"
        @change="_loadProyectos()"
        dense
        clearable
        flat
        solo
        hide-details
        item-text="name"
        item-value="val"
      ></v-select>

      <v-spacer></v-spacer>
    </v-toolbar>

    <v-card class="elevation-0">
      <v-data-table
        :headers="headers"
        :items="proyectos"
        class="elevation-0"
        :loading="loading"
        loading-text="Cargando mis proyectos..."
        hide-default-footer
        disable-pagination
      >
        <template v-slot:item.codigo="{ item }">
          <div
            class="cod_button"
            @click="$router.push(`/proyectos/${item.id}`)"
          >
            {{ item.codigo }}
          </div>
        </template>

        <template v-slot:item.estado="{ item }">
          <v-chip
            small
            label
            class="my-2"
            :color="_getChipEstado(item.estado.nombre)"
            outlined
            pill
          >
            {{ item.estado.nombre }}
          </v-chip>
        </template>

        <template v-slot:item.descripcion="{ item }">
          <div class="py-3">{{ _limitWords(item.descripcion, 10) }}</div>
        </template>

        <template v-slot:item.acciones="{ item }">
          <v-btn
            v-if="item.planificacion"
            color="primary"
            depressed
            class="mr-2"
            small
            dark
            :to="`/mis-proyectos/planificar/${item.id}`"
          >
            <v-icon small>
              mdi-pencil
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:no-data>
          <v-alert text type="info" class="text-left my-2" tile>
            No hay proyectos que mostrar.
          </v-alert>
        </template>
      </v-data-table>
    </v-card>

    <div class="mt-3 text-right">
      <v-pagination
        :length="pagination"
        v-model="page"
        class="elevation-0"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  data() {
    return {
      loading: true,
      page: 1,
      pagination: null,
      estado_id: 1,
      cliente_id: null,
      sortData: "DESC",
      sortDataOptions: [
        {
          val: "DESC",
          name: "Más Recientes",
        },
        {
          val: "ASC",
          name: "Más antiguos",
        },
      ],
      headers: [
        {
          text: "Código",
          align: "start",
          sortable: false,
          value: "codigo",
        },
        { text: "Estado", value: "estado" },
        { text: "Descripción", value: "descripcion", width: "30%" },
        { text: "Cliente", value: "cliente.nombre_comercial" },
        { text: "Fecha inicio", value: "fecha_inicio" },
        { text: "Fecha final", value: "fecha_final" },
        { text: "Acciones", value: "acciones" },
      ],
      proyectos: [],
    };
  },
  mounted() {
    this._loadProyectos();
  },
  watch: {
    page() {
      this._loadProyectos();
    },
  },
  computed: {
    ...mapState({
      _estados: "tipoEstados",
      _clientes: "clientes",
    }),
  },
  methods: {
    _loadProyectos() {
      this.loading = true;
      this.proyectos = [];
      let params = {
        params: {
          page: this.page,
          estado_id: this.estado_id,
          cliente_id: this.cliente_id,
          pagination: 30,
          sort: this.sortData,
        },
      };
      axios.get(`proyectos/tecnico`, params).then((res) => {
        if (res.data.status_code == 200) {
          this.proyectos = res.data.data.data;
          this.pagination = res.data.data.last_page;
        }
        this.loading = false;
      });
    },
    _getChipEstado(estado) {
      let color = "success";
      switch (estado) {
        case "Abierto":
          color = "success";
          break;
        case "Cerrado":
          color = "error";
          break;
        case "Abierto Fuera de Plazo":
          color = "warning";
          break;
        case "Inactivo":
          color = "grey";
          break;
      }
      return color;
    },
    _limitWords(textToLimit, wordLimit = 2, suffix = "...") {
      var finalText = "";
      var text2 = textToLimit.replace(/\s+/g, " ");
      var text3 = text2.split(" ");
      var numberOfWords = text3.length;
      var i = 0;
      if (numberOfWords > wordLimit) {
        for (i = 0; i < wordLimit; i++) {
          finalText = finalText + " " + text3[i] + " ";
        }
        return finalText + suffix;
      } else {
        return textToLimit;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.cod_button {
  cursor: pointer;
  &:hover {
    color: #1976d5;
  }
}

.table-buttons {
  min-width: 160px;
}
</style>
