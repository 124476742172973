<template>
  <div class="gastos">
    <notas-form :proyectoId="id_proyecto" :notaProp="nota"></notas-form>
  </div>
</template>

<script>
import axios from "axios";
import NotasForm from "@/components/NotasForm";
export default {
  components: {
    NotasForm,
  },
  data() {
    return {
      id_proyecto: null,
      id_nota: null,
      nota: [],
    };
  },
  mounted() {
    this.id_proyecto = this.$route.params.id_proyecto;
    this.id_nota = this.$route.params.id_nota;
    if (this.id_nota != "nueva") {
      this._getNota();
    } else {
      this.nota = false;
    }
  },
  methods: {
    _getNota() {
      axios.get(`nota/${this.id_nota}`).then((res) => {
        if (res.data.status_code != 200) {
          this.nota = false;
        }
        this.nota = res.data.data;
      });
    },
  },
};
</script>
